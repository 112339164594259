export const chefConfigs = {
  "spiritswap": {
     "title": "Spiritswap",
     "address": "0x9083EA3756BDE6Ee6f27a6e996806FBD37F6F093",
     "abi": require("./abis/SpiritSwapMasterChefABI.json"),
     "pendingRewardsMethod": "pendingSpirit",
     "rewardasset": "0x5cc61a78f164885776aa610fb0fe1257df78e59b"
  },
  "chad": {
    "title": "CHAD",
    "address": "0xDA094Ee6bDaf65c911f72FEBfC58002e5e2656d1",
    "abi": require("./abis/ChadMasterChefABI.json"),
    "pendingRewardsMethod": "pendingChad",
    "rewardasset": "0xCcE93540b80ABF71B66E0a44fd71E322cE9C4D9e"
  },
  "spookyswap": {
    "title": "Spookyswap",
    "address": "0x2b2929E785374c651a81A63878Ab22742656DcDd",
    "abi": require("./abis/SpookySwapMasterChefABI.json"),
    "pendingRewardsMethod": "pendingBOO",
    "rewardasset": "0x841fad6eae12c286d1fd18d1d525dffa75c7effe"
  },
  "paintswap": {
    "title": "PaintSwap",
    "address": "0xCb80F529724B9620145230A0C866AC2FACBE4e3D",
    "abi": require("./abis/PaintSwapMasterChefABI.json"),
    "pendingRewardsMethod": "pendingBrush",
    "rewardasset": "0x85dec8c4B2680793661bCA91a8F129607571863d"
  },
  "hyperjump": {
    "title": "Hyperjump",
    "address": "0x90Df158ff7c31aD1d81ddDb1D8ab9d0eCBCeDa20",
    "abi": require("./abis/HyperJumpMasterChefABI.json"),
    "pendingRewardsMethod": "pendingOrillium",
    "rewardasset": "0x0575f8738efda7f512e3654f277c77e80c7d2725"
  }/*,
  "steak": {
    "title": "Steak",
    "address": "0x59cC5f5F9309448Fe4a7Bd2dB8eB2DaC0F8fCEA7",
    "abi": require("./abis/SteakMasterChefABI.json"),
    "pendingRewardsMethod": "pendingSteak",
    "rewardasset": "0x05848B832E872d9eDd84AC5718D58f21fD9c9649"
  },
   */
  
}
 


  export const utilityConfigs = {
    "xsteak": {
      "title": "xSteak",
      "address": "0xb632c5d42BD4a44a617608Ad1c7d38f597E22E3C",
      "abi": require("./abis/xSteakABI.json")
    },
    "ifusd": {
      "title": "iFusd",
      "address": "0x9fC071cE771c7B27b7d9A57C32c0a84c18200F8a",
      "abi": require("./abis/ifusdABI.json")
    },
    "tombmasonry": {
      "title": "TombMasonry",
      "address": "0x8764DE60236C5843D9faEB1B638fbCE962773B67",
      "abi": require("./abis/TombMasonry.json")
    },
    "tshare": {
      "title": "tShare",
      "address": "0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37",
      "abi": require("../../GenericABI.json")
    },
    }




  //0x8764DE60236C5843D9faEB1B638fbCE962773B67

  //0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37


  export const hyperMechPools = {
    "mech": {
      "title": "Mechs",
      "address": "0x85c85647e1a79c2b8bc3ed2b6a1dde326eec66c5",
      "abi": require("./abis/HyperMechsABI.json")
    },
    "aurora": {
      "title": "AuroraMechPool",
      "address": "0x57798acFdC584721EDc761C698fe26F90B59eA3d",
      "abi": require("./abis/HyperMechPoolABI.json")
    },
  }

  export const routerConfig = {
    "hyperjump": {
      "title": "Hyperjump",
      "address": "0x53c153a0df7E050BbEFbb70eE9632061f12795fB",
      "abi": require("./routers/HyperJumpRouter.json")
    },
    "spookyswap": {
      "title": "SpookySwap",
      "address": "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
      "abi": require("./routers/SpookySwapRouter.json")
    },
    "spiritswap": {
      "title": "SpiritSwap",
      "address": "0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52",
      "abi": require("./routers/SpiritSwapRouter.json")
    },
 
  }