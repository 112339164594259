import { tokenConfigs } from './tokenconfig.js'
import { Joo3 } from './JooishWeb3.js'


export class MW {
	constructor () {
		const j3 = Joo3()
		this.go = new j3.eth.Contract(require("./WMATIC.json"),tokenConfigs["wmatic"]["address"])
	}
		
	

	wrapMatic = (value, callback) => {
		this.go.methods.deposit()
			.send({from: window.ethereum.selectedAddress, value: value})
			.then(callback)
	}

	unwrapMatic = (wad, callback) => {
		this.go.methods.withdraw(wad)
			.send({from: window.ethereum.selectedAddress})
			.then(callback)
	}
}


