import numeral from 'numeral';
import {formatTokenBalance, fromTokenNameTo, tokenNameIconMap } from './tokenUtilities.js'
import React from 'react'
export const WalletEntry = (props) => (
	
	<div className="wallet__entry">
		<div className="token-name">{props.tokenName}</div>
		<div className={"token-icon token-icon--" + props.tokenName}><img src={props.tokenImgSrc} /></div>
		<div className="token-balance">{formatTokenBalance(fromTokenNameTo(props.tokenName, "decimals"), props.tokenBalance)}</div>

	</div>
	
)

export const SimpleWalletEntry = (props) => (
	
	<div className="wallet__entry">
		<div className="token-name">{fromTokenNameTo(props.tokenName)}</div>
		<div className={"token-icon token-icon--" + props.tokenName}><img src={tokenNameIconMap.get(props.tokenName).icon} /></div>
		<div className="token-balance">{formatTokenBalance(fromTokenNameTo(props.tokenName, "decimals"), props.tokenBalance)}</div>
	</div>
	
)

export const Collapsor = (props) => {
	const [toggled, setToggled] = React.useState(false)
	const toggleIt = () => {
		if (toggled) {
			setToggled(false)
		} else {
			setToggled(true)
		}
	}
	return (
		<div 
			onClick={toggleIt} 
			className={"collapsible TOGGLE__collapsible--" + toggled}>
		  		{props.children}
			<div className="collapsible__coverBack"></div>
			<div className="collapsible__cover">{props.name}</div>
		</div>)
	

}
			