import { tokenConfigs } from './tokenconfig.js'
import { tokenNames, tokenNameIconMap, fromTokenNameTo, formatTokenBalance } from './tokenUtilities.js'
import { Token } from './token.js'
import React from 'react'
import { setsAreEquiv, getPairsFor } from './KnownPairs.js'

import { SwapRouter, XXX } from './JooishRouter.js'
import { swapXforY } from './swaprouter.js'
import { routerConfig } from './chains/matic/Configs.js';
import { joo, Joo } from './JooishWeb3.js'

import { MW } from './MaticWrapper.js'

import { BigNumber } from '@ethersproject/bignumber';
import numeral from 'numeral'

import { TokenSelector } from './SwapperUI.js'

import { lpTokenConfig } from './lptokenconfig.js'
import { PairGod } from './pairgod.js'

const maticPairs = getPairsFor("wmatic")

export const Forge = (props) => {
	const [selectedToken, setSelectedToken] = React.useState("wmatic")
	const [isSelectorOpen, setIsSelectorOpen] = React.useState(false)
	const [pairs, setPairs] = React.useState(maticPairs)
	const [isPairGodOpen, setIsPairGodOpen] = React.useState(false)
	const [thePair, setThePair] = React.useState([])
	const setSelected = (tokenName) => {
		setSelectedToken(tokenName)
		
	}

	const toggleSelector = () => {
		if (isSelectorOpen) {
		  setIsSelectorOpen(false)
		} else {
		  setIsSelectorOpen(true)
		}
	}

	React.useEffect(() => {
	    if (isSelectorOpen) {
	      
	      setIsSelectorOpen(false)
	      setPairs(getPairsFor(selectedToken))
	      toggleSelector()
	      
	    } else {
	      console.log("deh")
	    }
	}, [selectedToken])

	

	const focusPair = (event, sentTarget = null) => {

		let target = (event !== 0) ? forceTheEventToComeFromWhereIFuckingSayItShould(event) : sentTarget
	  
	    if (target.children.length < 1) return false

	    let TheNames = []
	    let ThePair = []

	    

	    for ( let kid of target.children ) {

	      if (kid.tagName !== "SPAN") {

	        TheNames.push( kid.className.replace("simpleToken SpekTok--","") )
	        ThePair.push( fromTokenNameTo( kid.className.replace("simpleToken SpekTok--",""), "address") )
	      }
	    }
	    setThePair(TheNames)
	    setIsPairGodOpen(true)
	    return [TheNames, ThePair]
	 

	  }

	const forceTheEventToComeFromWhereIFuckingSayItShould = (event) => {
		console.log(event.target.tagName)
		switch (event.target.tagName) {
		  case "IMG":
		    return event.target.parentElement.parentElement
		  case "DIV":
		    return event.target.parentElement
		  case "LI":
		    return event.target
		}
	}

	return (
		<div className={"forge forge--" + props.isOpen}>
			<div className="design-top-bar"></div>
			<div className="design-forge">LP FORGE</div>
			<div className="Selected__Token" onClick={toggleSelector}><Token name={selectedToken} /></div>
			<div className="Token__KnownPairs">
            <h2>known pairs</h2>
				<ul>{pairs.map(
					(x, index) => (
					<li key={index} onClick={focusPair}>{x.map( (y, index) => <Token key={index} name={y} />)}</li>))}</ul>
			</div>
			<TokenSelector isOpen={isSelectorOpen} onTokenSelect={setSelected} />
			<PairGod isOpen={isPairGodOpen} thePair={thePair}/>
			<button className="PairGod-close-btn" onClick={()=>{setIsPairGodOpen(false)}}>Close PairGod</button>
		</div>
		)	
}
