import React from 'react'
import { BigNumber } from '@ethersproject/bignumber';
import numeral from 'numeral';

import { SwapRouter } from './JooishRouter.js';

export function getAmountsOut (rtr, amtin, swapPath)  {
  return rtr.methods.getAmountsOut(amtin, swapPath)
}


function cleanSlippage (slippage, amounts, pathLength) {
	let cleanAmountOutMin = amounts[pathLength - 1];
    return BigNumber.from(100 - slippage).mul(cleanAmountOutMin).div(100);
}

export function swapXforY  (rtr, x, y, swapPath, amtin, slippage, doSomething)  {
  const millisecondsToWait = 60000
  const latest = Date.now()

  let tempPath = new Array();

  const ADDRESS_WMATIC = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"

  


  if ( x === ADDRESS_WMATIC || y === ADDRESS_WMATIC ) { // if swapping to or from FTM
    if ( x != ADDRESS_WMATIC && y === ADDRESS_WMATIC) { // if swapping to FTM
      let l1 = tempPath.push(x);
      let l2 = tempPath.push(y);

      let smartPath = tempPath;

      rtr.goGetAmountsOut(amtin, smartPath, 
      (amounts) => {

        let cleanAmountOutMin = cleanSlippage(slippage, amounts, smartPath.length);

        rtr.goSwapExactTokensForNative(
          amounts[0], 
          cleanAmountOutMin.toString(), 
          smartPath,
          doSomething)
      })


      	
    } else {
      let l1 = tempPath.push(x);
      let l2 = tempPath.push(y);

      let smartPath = tempPath;

      rtr.goGetAmountsOut(amtin, smartPath,
        (amounts) => {
          let cleanAmountOutMin = cleanSlippage(slippage, amounts, smartPath.length);

          rtr.goSwapExactNativeForTokens(amounts[0], cleanAmountOutMin.toString(), smartPath, doSomething)
        } 
      )
    }

  } else {
    let l1 = tempPath.push(x);
    let tempPath2 = tempPath.concat(swapPath);
    let l2 = tempPath2.push(y);

    let smartPath = tempPath2;

    rtr.goGetAmountsOut(amtin, smartPath, 
      (amounts) => {

        let cleanAmountOutMin = cleanSlippage(slippage, amounts, smartPath.length);

        rtr.goSwapExactTokensForTokens(
          amounts[0], 
          cleanAmountOutMin.toString(), 
          smartPath,
          doSomething)
      })


  }

} 

