import numeral from 'numeral';

import web3 from 'web3'

import { fromTokenNameTo, 
  formatTokenBalance, 
  fromAddressTo, 
  tokenNameAddyMap, 
  cleanFetchedName, 
  searchAddress,
  safeCompOrCleanName } from './tokenUtilities.js'
import { tokenConfigs } from './tokenconfig.js'
import { Token } from './token.js'

const mapAddressToDecimals = (address) => {
  switch (address) {
    case "0xF84BD51eab957c2e7B7D646A3427C5A50848281D":
      return 8
    default:
      return 18
  }

}

export const PoolNameRewardContainer = (props) => {
  return (
    <span className="PoolNameRewardContainer">
      <span className="PoolName">{props.name}</span>
      <span>{" \t"}</span>
      <button onClick={props.deposit}>Stake All</button>
      <span className="PoolBalance"><em>Staked:</em> {props.formattedBalance}</span>
      <span className="PoolRewards"><em>Pending:</em> {props.formattedRewards}</span>
    </span>

    )
}

// template with fields that all pools have
export class Pool {
  constructor(pid, name='NULL', rewards=0, lpToken="" , balance=0, deposit=null,  harvestEnabled=false) {
    this.pid            = pid;            // pool ID used by MasterChef
    this.name           = name;           // token/LP name of the pool asset

    this.rewards        = rewards;        // pending pool rewards from contract.pendingFish()
    this.lpToken        = lpToken;
    this.balance        = balance;
    this.deposit        = deposit;
    this.harvestEnabled = harvestEnabled; // is harvesting enabled in the dashboard for this pool?


    this.lpTokenDecimals = (typeof tokenNameAddyMap.get(lpToken) === 'undefined') ? 18 : fromTokenNameTo(searchAddress(lpToken), "decimals")

    // override JS object toString() for Pools
    this.toString = function() {
      let formattedRewards = formatTokenBalance(18, rewards)
      let formattedBalance = formatTokenBalance(this.lpTokenDecimals, balance)


      return (<span className="PoolNameRewardContainer">
                <button onClick={deposit}>Stake All</button>
                <span className="PoolBalance">
                  <em>Staked:</em>
                  <strong>{formattedBalance}</strong> 
                  <div className="PoolName">{name}</div>
                </span>
                <span className="PoolRewards"><em>Pending:</em> {formattedRewards}</span>
              </span>);
    }

    this.hasRewards = function () {
      return (this.rewards > 0)
    }
  }
  deepCopy() {
    return Object.assign({}, this)
  }
  static multiDeepCopy(poolsObj) {
    return Object.assign({}, this)
  }
}
