import { BigNumber } from '@ethersproject/bignumber';
import Web3 from 'web3'

function cleanSlippage (slippage, amounts, pathLength) {
  let cleanAmountOutMin = amounts[pathLength - 1];
    return BigNumber.from(100 - slippage).mul(cleanAmountOutMin).div(100);
}

export class IntentionToSwap {
  constructor(from, to) {
    this.from = from
    this.to = to
  }
}

const ADDRESS_WMATIC = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"

export class SwapRouter {
  constructor(captain, web3Instance, routerAbi, routerAddress) {
    this.captain = captain //wallet address for person piloting the instance
    this.web3 = web3Instance
    this.abi = routerAbi
    this.address = routerAddress
    this.loadedContract = new web3Instance.eth.Contract(routerAbi, routerAddress)
  }

  go () {
    return this.loadedContract.methods
  }

  goGetAmountsOut (amountIn, path, callback = () => {}) {
    this.go().getAmountsOut(amountIn, path).call({from: this.captain}).then(callback).catch(console.log("caught"))
  }

  goSwapExactNativeForTokens (payableAmount, amountOutMin, path, callback = () => {}) {
    this.go().swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, this.captain, Date.now() + 60000)
      .send({from: this.captain, value: payableAmount})
        .then(callback)
  }

  goSwapExactTokensForNative (amountIn, amountOutMin, path, callback = () => {}) {
    this.go().swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, this.captain, Date.now() + 60000)
      .send({from: this.captain})
        .then(callback)
  }

  goSwapExactTokensForTokens (amountIn, amountOutMin, path, callback = () => {}) {
    this.go().swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, this.captain, Date.now() + 60000)
      .send({from: this.captain})
        .then(callback)
  }

  goAddLiquidity(tokenA, tokenB, amountADesired, slippage = 2, callback = () => {}) {
    this.goGetAmountsOut(
      amountADesired,
      [tokenA, tokenB],
      (amounts) => {
        this.go().addLiquidity(tokenA, tokenB, amountADesired, amounts[1], cleanSlippage(slippage, amounts, 1), cleanSlippage(slippage, amounts, 2), this.captain, Date.now() + 60000)
          .send({from: this.captain})
            .then(callback)
      }
    )
  }
}

export function XXX (routerAbi, routerAddress) {
  return new SwapRouter(window.ethereum.selectedAddress, new Web3(Web3.givenProvider || 'http://localhost:8545'), routerAbi, routerAddress)
}


// const rtr = new SwapRouter(web3, routerConfig//)

// rtr.go().WETH
