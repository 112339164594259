import './App.scss';
import { YieldFarm } from './YieldFarm.js';

function App() {
  return (
    <div>
      <div className="App">
        <YieldFarm />
      </div>
      
    </div>
  );
}

export default App;
