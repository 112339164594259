import { curvePoolConfig } from './chains/matic/Configs.js'
import Web3 from 'web3'

const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')

export class CrvPool {
	constructor (configname) {
		this.X = web3
		this.abi = curvePoolConfig[configname]["abi"]
		this.address = curvePoolConfig[configname]["address"]
		this.lptoken = curvePoolConfig[configname]["lptoken"]
		this.tract = new web3.eth.Contract(
			curvePoolConfig[configname]["abi"], 
			curvePoolConfig[configname]["address"]) 
		this.coins = curvePoolConfig[configname]["coins"]
		this.underlyingcoins = curvePoolConfig[configname]["underlyingcoins"]
	}

	study () {
		console.log(this)
	}

	go () {
		return this.tract.methods
	}

	getDY (i, j, dx) {
		return this.tract.methods.get_dy_underlying(
			this.underlyingcoins.indexOf(i), 
			this.underlyingcoins.indexOf(j), 
			dx)
	}

	getDYUnderlying (i, j, dx) {

		return this.tract.methods.get_dy_underlying(
			this.underlyingcoins.indexOf(i), 
			this.underlyingcoins.indexOf(j), 
			dx)
	}

	calcTokenAmount (amts, is_deposit) {
		return this.tract.methods.calc_token_amount(amts, is_deposit)
	}


	addLiquidity (amts, minMintAmount, useUnderlying = true) {
		return this.tract.methods.add_liquidity(amts, minMintAmount, useUnderlying)
	}

	removeLiquidity (amts, minMintAmount, useUnderlying = true) {
		return this.tract.methods.remove_liquidity(amts, minMintAmount, useUnderlying)
	}

	exchange(i, j, dx, min_dy) {
		return this.tract.methods.exchange(
			this.underlyingcoins.indexOf(i), 
			this.underlyingcoins.indexOf(j),
			dx,
			min_dy)
	}

	exchangeUnderlying(i, j, dx, min_dy) {
		return this.tract.methods.exchange_underlying(
			this.underlyingcoins.indexOf(i), 
			this.underlyingcoins.indexOf(j),
			dx,
			min_dy)
	}





}
