export const KnownPairsSpirit = [
	["wftm","spirit"],
	["spirit","dai"]
]

export const PairsAsSetsSpirit = KnownPairsSpirit.map(x => new Set([...x]))

export const KnownPairsSpooky = [
	["wftm","boo"],
	["boo","usdc"]
]

export const PairsAsSetsSpooky = KnownPairsSpooky.map(x => new Set([...x]))

export const setsAreEquiv = (A, B) => {
	if (A.size !== B.size) return false
	for (let a of A) if (!B.has(a)) return false
	return true
}

export const getKP = () => {

	let PairsSpirit = new Set([...PairsAsSetsSpirit]);

	let PairsSpooky = new Set([...PairsAsSetsSpirit]);
	let KP = new Set([...PairsAsSetsSpirit]);

	
	PairsAsSetsSpirit.forEach( pairSpirit => {
		let shouldNotAdd = false;
		PairsAsSetsSpooky.forEach( pairSpooky => {
			if (!shouldNotAdd) {
				if (setsAreEquiv(pairSpirit, pairSpooky)) {
					// this quick pair should not be added
					shouldNotAdd = true
				} 
			}
		}) // end of Dfyn Loop
		if ( shouldNotAdd !== true ) KP.add(pairSpirit)
	})




/*
	let _KP = KP
	PairsAsSetsSushi.forEach( pairSushi => {
		let shouldNotAdd = false;
		_KP.forEach( pair => {
			if (!shouldNotAdd) {
				if (setsAreEquiv(pair, pairSushi)) {
					// this sushi pair should not be added
					shouldNotAdd = true
				} 
			}
		}) // end of KP Loop
		if ( shouldNotAdd !== true ) KP.add(pairSushi)
	}) */
	
	
		
	return Array.from(KP)
}


 
// export const KnownPairs = [...new Set([...PairsAsSetsQuick, ...PairsAsSetsQuick])]
// export const KnownPairs = [...new Set([...KnownPairsDfyn, ...KnownPairsQuick])]


export const getPairsFor = (tokenName, isIn = true) => {


	let MutableKnowledge = new Array();

	getKP().forEach((x, i) => {
		let _x = new Set(x)

		if (_x.has(tokenName)) {
	
			_x.delete(tokenName)

			let _Ax = Array.from(_x)


			if (isIn) MutableKnowledge.push( [tokenName, _Ax[0]] )

			if (!isIn) MutableKnowledge.push( [_Ax[0], tokenName] )
		}
	})

	


	return MutableKnowledge
}


