import { Joo } from './JooishWeb3.js'

export class MasterChef {
  constructor(_web3, { abi, address, pendingRewardsMethod, baseAssetPid=1 }) {
    console.log("Constructing MasterChef: " + this.constructor.name)
    this._web3 = _web3
    this._contract = new this._web3.eth.Contract(abi, address)
    this._pendingRewardsMethod = this._contract.methods[pendingRewardsMethod]
    // Pool ID of the base asset of this chef (e.g. for Polycat the FISH pool has PID=1)
    this._baseAssetPid = baseAssetPid
    this._j = new Joo(_web3)
  }

  static callOrRequest(_walletAddress, transaction, callback, requestMode=false) {
    // make a contract call or request with the given callback
    if (requestMode) {
      return transaction.call.request({from: _walletAddress}, callback)
    } else {
      return transaction.call({from: _walletAddress}).then(callback)
    }
  }

  probeNumPools(_walletAddress, callback=() => {}, requestMode=false) {
    // poolLength - numberof pools in a MasterChef
    return MasterChef.callOrRequest(_walletAddress, this._contract.methods.poolLength(), callback, requestMode)
  }

  probePoolInfo(_pid, _walletAddress, callback=() => {}, requestMode=false) {
    // poolInfo - general information for a pool
    return MasterChef.callOrRequest(_walletAddress, this._contract.methods.poolInfo(_pid), callback, requestMode)
  }

  probeUserInfo(_pid, _walletAddress, callback=() => {}, requestMode=false) {
    // userInfo - information about a wallet/user for a pool
    return MasterChef.callOrRequest(_walletAddress, this._contract.methods.userInfo(_pid, _walletAddress), callback, requestMode)
  }

  probePoolName(_tokenAddress, _walletAddress, callback=() => {}, requestMode=false) {
    // pool token/LP name - use generic contract ABI to get the name of this pool's asset
 
    let genContract = this._j.createGenContract(_tokenAddress)
    return MasterChef.callOrRequest(_walletAddress, genContract.methods.name(), callback, requestMode)
  }

  probePendingRewards(_pid, _walletAddress, callback=() => {}, requestMode=false) {
    // pending* - pending rewards in pool for this wallet address
    //   pending rewards method is set in the cosntructor because it is different for each chef contract
    return MasterChef.callOrRequest(_walletAddress, this._pendingRewardsMethod(_pid, _walletAddress), callback, requestMode);
  }

  deposit(_pid, _amount, _walletAddress) {
    return this._contract.methods.deposit(_pid, _amount).send({from: _walletAddress})
  }

  harvestPool(_pid, _walletAddress) {
    return this.deposit(_pid, 0, _walletAddress)
  }

  stakeAllChefAsset(_walletAddress, afterthought = () => {}) {
    let j = new Joo(this._web3)
    this.probePoolInfo(this._baseAssetPid, _walletAddress,
      (_info) => {
        let tokenAddress = _info[0]
        j.grabTokenBalance__THEN(tokenAddress, _walletAddress,
          (balance) => {
            console.log(balance)
            this.deposit(this._baseAssetPid, balance, _walletAddress).then(afterthought)
          }
        )
      }
    )
  }
}

export function masterChefFactory(_web3, _chefConfig) {
  const constr = ("chefClass" in _chefConfig) ? _chefConfig["chefClass"] : MasterChef
  return new constr(_web3, _chefConfig)
}
