
import { dfynRewardFactoryConfig } from './chains/matic/Configs.js'
import { formatTokenBalance,  
         fromTokenNameToAddress } from './tokenUtilities.js'
import { LazyToken } from './token.js'

 const dfynFromPoolNameToTokenNames = (poolName) => {
      return [
        dfynRewardFactoryConfig[poolName]["tokenA"], 
        dfynRewardFactoryConfig[poolName]["tokenB"]
      ]
    }

    

 export const DfynPool = (props) => {

      let tokens = dfynFromPoolNameToTokenNames(props.poolName);

      return (
   
          <div className="dfyn-pool">
            <div className="dfyn-pair">{props.poolName}</div>
            <div className="LP-Token">
              <LazyToken name={tokens[0]} />
              <LazyToken name={tokens[1]} />
            </div>
            <div className="staked-balance">Staked: {formatTokenBalance(18, props.stakedBalance)}</div>
            <div className="dfyn-rewards" onClick={props.harvest}>
              <h3><span>Pending Rewards</span><span>Harvest</span></h3>
              <div className="reward">
                <span>{ formatTokenBalance(18, props.earnedAmountOne) }</span>
                <LazyToken name={tokens[0]} />
              </div>
              <div className="reward">
                <span>{ formatTokenBalance(18, props.earnedAmountTwo) }</span>
                <LazyToken name={tokens[1]} />
              </div>
            </div>
          </div>


      )
    }
