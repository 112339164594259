  export const logDate = () => {
    var currentdate = new Date();
    var datetime = currentdate.getDate() + "/"
                 + (currentdate.getMonth()+1)  + "/"
                 + currentdate.getFullYear() + " @ "
                 + currentdate.getHours() + ":"
                 + currentdate.getMinutes() + ":"
                 + currentdate.getSeconds();
    console.log("\tCurrent date+time: " + datetime)
  }
