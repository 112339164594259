import React from 'react';
import { tokenConfigsFTM } from './tokenconfig.js'
import { tokenNameIconMap } from './tokenUtilities.js'

import { LazyLoadImage } from 'react-lazy-load-image-component'

const tokenConfigs = tokenConfigsFTM
export function BalanceWithIcon (props) {
 return (
   <span className="balance"><span>{props.tokenBalance}</span><LazyLoadImage src={props.tokenIcon} /></span>
   )
}

export const Token = (props) => {
  if (typeof(tokenConfigs[props.name]) === 'undefined') {
    return (
      <div className={"simpleToken SpekTok--" + props.name}></div>
      )
  } else {
    return (
     <div className={"simpleToken SpekTok--" + props.name}><LazyLoadImage src={tokenConfigs[props.name]["icon"]["default"]} /></div>
     )
  }

 
}

export const LP =  (props) => {
 return <span className="LP">{props.children}</span>
}
export const Lizzy = (props) => {
  return <div className="LP"><Token name={props.pair[0]} /><Token name={props.pair[1]} /></div>
  }

export const TokenImg = (props) => {
  return <img src={props.src} />
}

export const LazyTokenImageSource = (props) => {
  return React.lazy(
    () => import(<img src={tokenNameIconMap.get(props.name).icon} />))
}

export const LazyToken = (props) => {
  return (
    <LazyLoadImage src={tokenConfigs[props.name]["icon"]["default"]} />
  )
}
