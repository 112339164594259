export const tokenConfigs = {
	"wmatic": {
		"name": "Wrapped Matic",
		"address": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
		"icon": require("./images/token-icons/matic.png"),
		"decimals": 18
	},
	"fish": {
		"name": "Fish",
		"address": "0x3a3df212b7aa91aa0402b9035b098891d276572b",
		"icon": require("./images/token-icons/fish.png"),
		"decimals": 18
	},
	"dfyn": {
		"name": "DFYN",
		"address": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
		"icon": require("./images/token-icons/dfyn.png"),
		"decimals": 18
	},
	"aurora": {
		"name": "Aurora",
		"address": "0x0c8C8Ae8bc3a69dC8482C01CEacfB588bb516B01",
		"icon": require("./images/token-icons/aurora.png"),
		"decimals": 18
	},
	"solar": {
		"name": "Solar",
		"address": "0x5FBccc4Acc0B9339D7CDC5d6336A5c88D89e5327",
		"icon": require("./images/token-icons/solar.png"),
		"decimals": 18
	},
	"route": {
		"name": "Route",
		"address": "0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4",
		"icon": require("./images/token-icons/route.png"),
		"decimals": 18
	},
	"booty": {
		"name": "Booty",
		"address": "0xd12dc5319808bb31ba95ae5764def2627d5966ce",
		"icon": require("./images/token-icons/booty.png"),
		"decimals": 18
	}, 
	"zee": {
		"name": "Zee",
		"address": "0xfd4959c06fbcc02250952daebf8e0fb38cf9fd8c",
		"icon": require("./images/token-icons/zee.webp"),
		"decimals": 18
	},
	"gaj": {
		"name": "PolyGaj",
		"address": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
		"icon": require("./images/token-icons/gaj.png"),
		"decimals": 18
	},
	"polydoge": {
		"name": "PolyDoge",
		"address": "0x8a953cfe442c5e8855cc6c61b1293fa648bae472",
		"icon": require("./images/token-icons/polydoge.png"),
		"decimals": 18
	},
	"quick": {
		"name": "Quick",
		"address": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
		"icon": require("./images/token-icons/quick.png"),
		"decimals": 18
	},
	"dai": {
		"name": "Dai",
		"address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
		"icon": require("./images/token-icons/dai.png"),
		"decimals": 18
	},
	"mai": {
		"name": "Mai",
		"address": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
		"icon": require("./images/token-icons/mai.png"),
		"decimals": 18
	},
	"qi": {
		"name": "Qi",
		"address": "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
		"icon": require("./images/token-icons/qi.png"),
		"decimals": 18
	},
	"wolf": {
		"name": "Wolf",
		"address": "0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16",
		"icon": require("./images/token-icons/wolf.png"),
		"decimals": 9
	},
	"usdc": {
		"name": "USDC",
		"address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
		"icon": require("./images/token-icons/usdc.png"),
		"decimals": 6
	},
	"usdt": {
		"name": "USDT",
		"address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
		"icon": require("./images/token-icons/usdt.png"),
		"decimals": 6
	},
	"amdai": {
		"name": "AaveDAI",
		"address": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
		"icon": require("./images/token-icons/dai.png"),
		"decimals": 18
	},
	"amusdc": {
		"name": "AaveUSDC",
		"address": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
		"icon": require("./images/token-icons/usdc.png"),
		"decimals": 6
	},
	"amwbtc": {
		"name": "AaveWBTC",
		"address": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
		"icon": require("./images/token-icons/btc.svg"),
		"decimals": 8
	},
	"amweth": {
		"name": "AaveWETH",
		"address": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
		"icon": require("./images/token-icons/eth.png"),
		"decimals": 18
	},
	"amwmatic": {
		"name": "AaveWMATIC",
		"address": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
		"icon": require("./images/token-icons/matic.png"),
		"decimals": 18
	},
	"eth": {
		"name": "WETH",
		"address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
		"icon": require("./images/token-icons/eth.png"),
		"decimals": 18
	},
	
	"wbtc": {
		"name": "WBTC",
		"address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
		"icon": require("./images/token-icons/btc.svg"),
		"decimals": 8
	},

	"crv": {
		"name": "Curve",
		"address": "0x172370d5cd63279efa6d502dab29171933a610af",
		"icon": require("./images/token-icons/curve.png"),
		"decimals": 18
	}, 
	"sushi": {
		"name": "Sushi",
		"address": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
		"icon": require("./images/token-icons/sushi.png"),
		"decimals": 18
	},
	"omen": {
		"name": "Omen",
		"address": "0x76e63a3E7Ba1e2E61D3DA86a87479f983dE89a7E",
		"icon": require("./images/token-icons/omen.png"),
		"decimals": 18
	},
	"wexpoly": {
		"name": "Wexpoly",
		"address": "0x4c4bf319237d98a30a929a96112effa8da3510eb",
		"icon": require("./images/token-icons/WEXPOLY.png"),
		"decimals": 18

	},
	"aave": {
		"name": "Aave",
		"address": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
		"icon": require("./images/token-icons/aave.svg"),
		"decimals": 18
		
	}, 
	"banana": {
		"name": "Banana",
		"address": "0x5d47baba0d66083c52009271faf3f50dcc01023c",
		"icon": require("./images/token-icons/banana.png"),
		"decimals": 18
		
	}, 
	"link": {
		"name": "Chainlink",
		"address": "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
		"icon": require("./images/token-icons/link.png"),
		"decimals": 18
		
	}, 
	"nebula": {
		"name": "Nebula",
		"address": "0xba47320eF6B1039F19F3C4096F0f3B3e8Eb8d1F8",
		"icon": require("./images/token-icons/nebula.png"),
		"decimals": 18
		
	},
	"bifi": {
		"name": "Beefy",
		"address": "0xfbdd194376de19a88118e84e279b977f165d01b8",
		"icon": require("./images/token-icons/bifi.png"),
		"decimals": 18
	},
	"agar": {
		"name": "AgaR",
		"address": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
		"icon": require("./images/token-icons/agar.png"),
		"decimals": 8
	},
	"aga": {
		"name": "Aga",
		"address": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
		"icon": require("./images/token-icons/aga.png"),
		"decimals": 8
	},
	"agacr": {
		"name": "AgaCR",
		"address": "0xcbce9f77921c2e90522d438df4c5582f4c617768",
		"icon": require("./images/token-icons/agacr.png"),
		"decimals": 18
	},
	"agac": {
		"name": "AgaC",
		"address": "0x669ddc70273084ea30e6cd4f28ca6e2c70735065",
		"icon": require("./images/token-icons/agac.png"),
		"decimals": 18
	},
	"elk": {
		"name": "Elk",
		"address": "0xe1c8f3d529bea8e3fa1fac5b416335a2f998ee1c",
		"icon": require("./images/token-icons/elk.svg"),
		"decimals": 18
	},
	"moon": {
		"name": "Moon",
		"address": "0xc56d17dD519e5eB43a19C9759b5D5372115220BD",
		"icon": require("./images/token-icons/moon.png"),
		"decimals": 18
	},
	"koge": {
		"name": "Kogecoin",
		"address": "0x13748d548D95D78a3c83fe3F32604B4796CFfa23",
		"icon": require("./images/token-icons/koge.png"),
		"decimals": 9
	},
	"cel": {
		"name": "Celsius",
		"address": "0xd85d1e945766fea5eda9103f918bd915fbca63e6",
		"icon": require("./images/token-icons/cel.png"),
		"decimals": 4
	},
	"tel": {
		"name": "Telcoin",
		"address": "0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32",
		"icon": require("./images/token-icons/tel.png"),
		"decimals": 2
	},
	"addy": {
		"name": "Adamant",
		"address": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
		"icon": require("./images/token-icons/addy.png"),
		"decimals": 18
	}	


}


export const tokenConfigsFTM = {
	"wftm": {
		"name": "Wrapped Fantom",
		"address": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
		"icon": require("./images/token-icons/wftm.png"),
		"decimals": 18
	},
	"usdc": {
		"name": "USDC",
		"address": "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
		"icon": require("./images/token-icons/usdc.png"),
		"decimals": 6
	},
	"dai": {
		"name": "DAI",
		"address": "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
		"icon": require("./images/token-icons/dai.png"),
		"decimals": 18
	},
	"spirit": {
		"name": "SPIRIT",
		"address": "0x5cc61a78f164885776aa610fb0fe1257df78e59b",
		"icon": require("./images/token-icons/spirit.png"),
		"decimals": 18
	},
	"chad": {
		"name": "CHAD",
		"address": "0xCcE93540b80ABF71B66E0a44fd71E322cE9C4D9e",
		"icon": require("./images/token-icons/chad.png"),
		"decimals": 18
	},
	"boo": {
		"name": "BOO",
		"address": "0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE",
		"icon": require("./images/token-icons/BOO.png"),
		"decimals": 18
	},
	"xboo": {
		"name": "XBOO",
		"address": "0xa48d959AE2E88f1dAA7D5F611E01908106dE7598",
		"icon": require("./images/token-icons/xBOO.png"),
		"decimals": 18
	},
	"steak": {
		"name": "Steak",
		"address": "0x05848B832E872d9eDd84AC5718D58f21fD9c9649",
		"icon": require("./images/token-icons/steak.png"),
		"decimals": 18
	},
	"xsteak": {
		"name": "xSteak",
		"address": "0xb632c5d42BD4a44a617608Ad1c7d38f597E22E3C",
		"icon": require("./images/token-icons/xsteak.png"),
		"decimals": 18
	},
	"brush": {
		"name": "Brush",
		"address": "0x85dec8c4B2680793661bCA91a8F129607571863d",
		"icon": require("./images/token-icons/brush.png"),
		"decimals": 18
	},
	"fusd": {
		"name": "fUSD",
		"address": "0xad84341756bf337f5a0164515b1f6f993d194e1f",
		"icon": require("./images/token-icons/fusd.png"),
		"decimals": 18
	}, 
	"ifusd": {
		"name": "ifUSD",
		"address": "0x9fC071cE771c7B27b7d9A57C32c0a84c18200F8a",
		"icon": require("./images/token-icons/ifusd.png"),
		"decimals": 18
	},
	"aurora": {
		"name": "Aurora",
		"address": "0xbc2451AaD349b6B43FD05F4F0cC327F8A6bcA2d4",
		"icon": require("./images/token-icons/hyperjump__aurora.png"),
		"decimals": 18
	},
	"ori": {
		"name": "Orillium",
		"address": "0x0575f8738efda7f512e3654f277c77e80c7d2725",
		"icon": require("./images/token-icons/hyperjump__ori.png"),
		"decimals": 18
	},
	"tomb": {
		"name": "Tomb",
		"address": "0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7",
		"icon": require("./images/token-icons/tomb.svg"),
		"decimals": 18
	},
	"tshare": {
		"name": "Tomb",
		"address": "0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37",
		"icon": require("./images/token-icons/tshare.svg"),
		"decimals": 18
	}

	



}



