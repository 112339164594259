import { tokenConfigs } from './tokenconfig.js'
import { tokenNames, tokenNameIconMap, fromTokenNameTo, formatTokenBalance } from './tokenUtilities.js'
import { Token } from './token.js'
import React from 'react'
import { setsAreEquiv, getPairsFor } from './KnownPairs.js'

import { SwapRouter, XXX } from './JooishRouter.js'
import { swapXforY } from './swaprouter.js'
import { curvePoolConfig } from './chains/matic/Configs.js';
import { joo, Joo } from './JooishWeb3.js'



import { BigNumber } from '@ethersproject/bignumber';
import numeral from 'numeral'

import { TokenSelector } from './SwapperUI.js'


import { CrvPool } from './crv.js'

const ZONE1 = "swap"
const ZONE2 = "deposit"
const ZONE3 = "stake"

const ThreeLP = (props) => {
	return (
		<div className="ThreeLP">
			<Token name={props.one} />
			<Token name={props.two} />
			<Token name={props.three} />
		</div>

		)
}

const CrvPoolCoin = (props) => {
	return (
		<div className={
			"curve-pool__token curve-pool__token--" + 
			props.name + 
			" selected-in--" + props.selectedTokenIN + " selected-out--" + props.selectedTokenOUT}
			onClick={props.onClick}>
			<div className="Selected__Token"><Token name={props.name} /></div>

			<div className="Info">
				<div className="balance">
					<span>Wallet: </span><span>{formatTokenBalance(fromTokenNameTo(props.name, "decimals"), props.walletBalance)}</span>
				</div>
			</div>
		</div>

		)
}

const Zone = (props) => {
	return (
		<div className={"zone zone-" + props.name + " selected--" + props.selectedZone}>
			{props.children}
		</div>
		)
}

const cp = new CrvPool("aave");
export const CrvGod = (props) => {
	let j = new Joo(props.web3)
	const [pendingTransaction, setPendingTransaction] = React.useState(false)
	const [slippage, setSlippage] = React.useState(10) // 0.1 => 1 
	const poolConfig = curvePoolConfig[props.name];
	
	
	const [selectedZone, setSelectedZone] = React.useState(ZONE1)
	const setSelectedZoneONCLICK = (zone) => {
		return () => {setSelectedZone(zone)}
	}

	const [selectedTokenIN, setSelectedTokenIN] = React.useState(cp.underlyingcoins[0])
  	const [selectedTokenOUT, setSelectedTokenOUT] = React.useState(cp.underlyingcoins[1])

  	const [justSetIN, setJustSetIN] = React.useState(false)
  	const smartSetINOUT = (tkn) => {
  		if (tkn === selectedTokenIN) {

			let x = selectedTokenOUT
			setSelectedTokenOUT(tkn)
			setSelectedTokenIN(x)
			setJustSetIN(false)
  			
  		} else if ( tkn === selectedTokenOUT ) {
  			let x = selectedTokenIN
			setSelectedTokenIN(tkn)
			setSelectedTokenOUT(x)
			setJustSetIN(true)
  		} else {
  			if (justSetIN) {
	  			if (tkn !== selectedTokenIN){
	  				setSelectedTokenOUT(tkn)
	  				setJustSetIN(false)
	  			} 
	  			
	  		} else {
	  			if (tkn !== selectedTokenOUT){
	  				setSelectedTokenIN(tkn)
	  				setJustSetIN(true)
	  			}

	  		}
  		}
  		
  	}

  	const [nonVisibleSwapInAmount, setNonVisibleSwapInAmount] = React.useState(0)
  	const [nonVisibleSwapOutAmount, setNonVisibleSwapOutAmount] = React.useState(0)
  	const [swapOutAmount, setSwapOutAmount] = React.useState(0)

	const swapInAmount = React.createRef()

	const setInputViaButtonClick = (percentage) => {
      const j = new Joo(props.web3);
      j.grabTokenBalanceFromConfig(
        tokenConfigs, 
        selectedTokenIN, 
        window.ethereum.selectedAddress,
        (bal) => {
          swapInAmount.current.value = formatTokenBalance(
            fromTokenNameTo(selectedTokenIN, "decimals"),
            BigNumber.from(percentage).mul(bal).div(100))
          setNonVisibleSwapInAmount(BigNumber.from(percentage).mul(bal).div(100));
        })

  }

	
	const SwapAmtBtn = (props) => {
	    return <button onClick={()=>{setInputViaButtonClick(props.pct)}}>{props.pct}%</button>
	  }

	 const checkCrv = () => {
	 	cp.getDYUnderlying(
	 		selectedTokenIN, 
	 		selectedTokenOUT, 
	 		nonVisibleSwapInAmount)
	 		.call({from: window.ethereum.selectedAddress})
	 		.then( (res) => {
	 			setNonVisibleSwapOutAmount(res)
	 			setSwapOutAmount(
	 				formatTokenBalance(
			            fromTokenNameTo(selectedTokenOUT, "decimals"),
			            BigNumber.from(1000 - slippage).mul(res).div(1000))
	 			)
	 		}) 
	 }

	 const checkAllowance = (callback) => {
	 	j.allowance(
	 		fromTokenNameTo(selectedTokenIN,"address"),
	 		cp.address,
	 		window.ethereum.selectedAddress,
	 		callback

	 		)
	 }

	 const approveCrv = (callback) => {
	 	setPendingTransaction(true)
	
	 	j.approve(
	 		fromTokenNameTo(selectedTokenIN, "address"), 
	 		cp.address, 
	 		window.ethereum.selectedAddress,
	 		nonVisibleSwapInAmount,
	 		() => {
	 			setPendingTransaction(false)
	 			callback()
	 		}

	 		)
	 	

	 }

	 const swapCrv = () => {
	 	setPendingTransaction(true)
	 	cp.exchangeUnderlying(
			selectedTokenIN, 
			selectedTokenOUT, 
			nonVisibleSwapInAmount,
			nonVisibleSwapOutAmount)
	 		.send({from: window.ethereum.selectedAddress})
	 		.then((res) => {
	 			setPendingTransaction(false)
	 		})
		
	 		
	 }

	 const smartSwap = () => {
	 	checkAllowance(
	 		(res) => {
	 			console.log(res)
	 			if (res >= nonVisibleSwapInAmount) {
	 				swapCrv()
	 			} else {
	 				approveCrv(
	 					(res) => {
	 						swapCrv()
	 					}
	 					)
	 			}
	 		}

	 		)
	 }



	return (
		<div className={"CrvGod CrvGod--" + props.isOpen}>
			<div className="design-top-bar"></div>
			<div className="design-forge">Curve.fi</div>
			<div className={"zone-select zone-select--" + selectedZone}>
				<button className="button zone-button" onClick={setSelectedZoneONCLICK(ZONE1)} >
					Swap
				</button>
				<button className="button zone-button" onClick={setSelectedZoneONCLICK(ZONE2)} >
					Deposit || Withdraw
				</button>
				<button className="button zone-button" onClick={setSelectedZoneONCLICK(ZONE3)} >
					Stake || Unstake
				</button>
			</div>
			<Zone name="swap" selectedZone={selectedZone}>
				<div className="balance-LP">
					<ThreeLP 
						one={poolConfig["underlyingcoins"][0]} 
						two={poolConfig["underlyingcoins"][1]} 
						three={poolConfig["underlyingcoins"][2]} />
				</div>
				<div className="curve-pool">
					<CrvPoolCoin 
						name={poolConfig["underlyingcoins"][0]} 
						walletBalance={props.walletBalances[0]}
						selectedTokenIN = {selectedTokenIN}
						selectedTokenOUT = {selectedTokenOUT}
						onClick={() => {smartSetINOUT(poolConfig["underlyingcoins"][0])}}
						/>
					<CrvPoolCoin 
						name={poolConfig["underlyingcoins"][1]} 
						walletBalance={props.walletBalances[1]}
						selectedTokenIN = {selectedTokenIN}
						selectedTokenOUT = {selectedTokenOUT}
						onClick={() => {smartSetINOUT(poolConfig["underlyingcoins"][1])}}
						/>
					<CrvPoolCoin 
						name={poolConfig["underlyingcoins"][2]} 
						walletBalance={props.walletBalances[2]}
						selectedTokenIN = {selectedTokenIN}
						selectedTokenOUT = {selectedTokenOUT}
						onClick={() => {smartSetINOUT(poolConfig["underlyingcoins"][2])}}/>
				</div>
				<div className="CrvSwap">
					<input type="text" ref={swapInAmount}/>
					<div className="Swapper__Input">
			            <h3>Swap</h3>
			            
			            <div className="amount-btns amount-btns--2">
			              <SwapAmtBtn pct="55" />
			              <SwapAmtBtn pct="100" />
			            </div>
			            <div className="amount-btns">
			              <SwapAmtBtn pct="5" />
			              <SwapAmtBtn pct="10" />
			              <SwapAmtBtn pct="25" />
			              <SwapAmtBtn pct="50" />
			  
			            </div>
			            <button className="button" onClick={checkCrv}>Check</button>
			            <button className="button" onClick={smartSwap}>Swap</button>
			        </div>
			        <div className="CrvGod__Swap-output">
			        	<h3>Output Amount</h3>
			        	<h3>{swapOutAmount}</h3>
			        </div>
			    </div>
	        </Zone>
	       <Zone name="deposit" selectedZone={selectedZone}>
				
		       
	        </Zone>
	        <Zone name="stake" selectedZone={selectedZone}>
				
		       
	        </Zone>

	        <div className={"pending pending--" + pendingTransaction}>PENDING</div>
		</div>
		)	
}
