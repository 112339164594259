import { PolycatMasterChef,
         WEXPolyMasterChef,
         AuguryMC
       } from './Chefs.js'

export const chefConfigs = {
  "polycat": {
     "title": "Polycat",
     "address": "0x8CFD1B9B7478E7B0422916B72d1DB6A9D513D734",
     "abi": require("./abis/PolycatMasterChefABI.json"),
     "chefClass": PolycatMasterChef,
     "pendingRewardsMethod": "pendingFish"
  },
  "polygaj": {
     "title": "PolyGaj",
     "address": "0xb03f95E649724dF6bA575C2c6eF062766a7fDb51",
     "abi": require("./abis/PolyGajMasterChefABI.json"),
     "pendingRewardsMethod": "pendingEgg"

  },
  "wexpoly": {
     "title": "WEXPoly",
     "address": "0xC8Bd86E5a132Ac0bf10134e270De06A8Ba317BFe",
     "abi": require("./abis/WEXPolyMasterChefABI.json"),
     "chefClass": WEXPolyMasterChef,
     "pendingRewardsMethod": "pendingWex"
  },
  "piratebooty": {
     "title": "PirateBooty",
     "address": "0xA7f3C3f80Ff6a6f31bB7BaB04E3E8AC4E4dAE0c3",
     "abi": require("./abis/PirateBootyMasterChefABI.json"),
     "baseAssetPid": 8,
     "pendingRewardsMethod": "pendingBooty"
  },
  "aurora": {
     "title": "Aurora",
     "address": "0x14790e89a52E207956A90f0ddBcd6C255315Af6B",
     "abi": require("./abis/AuroraMasterChefABI.json"),
     "pendingRewardsMethod": "pendingAurora"
  },
  "solar": {
     "title": "Solar",
     "address": "0x74284baEDb904486cec0091002b8E6a602977593",
     "abi": require("./abis/SolarMasterChefABI.json"),
     "pendingRewardsMethod": "pendingSolar"
  },
  "augury": {
     "title": "Augury",
     "address": "0x6ad70613d14c34aa69E1604af91c39e0591a132e",
     "abi": require("./abis/AuguryMCABI.json"),
     "chefClass": AuguryMC,
     "pendingRewardsMethod": "pendingOmen"
  },
  "moonwolf": {
     "title": "Moonwolf",
     "address": "0xB0a8e90FF02D2918eF74C46C2dEfE7405126239F",
     "abi": require("./abis/MasterWolfABI.json"),
     "pendingRewardsMethod": "pendingMoon"
  }
}


export const addyConfig = {
  "multifee": {
     "title": "MultiFee",
     "address": "0x920f22E1e5da04504b765F8110ab96A20E6408Bd",
     "abi": require("../../addy/abis/FeeDistribution.json")
  },
  "deployer": {
     "title": "Deployer",
     "address": "0x5377E8316956b8a9113b4c5CB3071516948c1e06",
     "abi": require("../../addy/abis/Deployer.json")
  },
  "calculator": {
     "title": "Calculator",
     "address": "0xAc2F66971BC37eB443c4E11AAb277e19d1C4864C",
     "abi": require("../../addy/abis/Oracle.json")
  }
}

// PolyLionMC 0x00275072A952f7731d507dc5deC9Bcb27c13cfc3

export const routerConfig = {
  "dfyn": {
     "title": "Dfyn",
     "address": "0xA102072A4C07F06EC3B4900FDC4C7B80b6c57429",
     "abi": require("../../swapABIs/dfynRouter.json")
  },
  "quickswap": {
     "title": "Quickswap",
     "address": "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
     "abi": require("../../swapABIs/quickswapRouter.json")
  },
  "sushi": {
     "title": "Sushiswap",
     "address": "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
     "abi": require("../../swapABIs/sushiRouter.json")
  },
  "elk": {
     "title": "Elk Finance",
     "address": "0xf38a7A7Ac2D745E2204c13F824c00139DF831FFf",
     "abi": require("../../swapABIs/elkRouter.json")
  }
  
  
}


export const dfynRewardsConfig = {
  "rewardfactory": {
    "address": "0xdC927991e43E1D57A1Af7E80Ea630f6Fb7915691",
    "abi": require("./abis/DFYN_StakingRewardsFactoryABI.json")
  }
}

export const dfynRewardFactoryConfig = {
  "route-dfyn": {
    "address": "0xf997c8e2e7e7387C8fd9120280ad9B3db31A5381",
    "stakingToken": "0xB0dc320ea9eea823a150763AbB4A7bA8286Cd08B",
    "tokenA": "route",
    "tokenB": "dfyn"
  },
  "aurora-dfyn": {
    "address": "0x4Dd06f2D7746330C279Fdfa7a75407165eb1D845",
    "stakingToken": "0x5aB85D3bB0DAff2f42fD6FD07D3b2C0DF94AdaC6",
    "tokenA": "aurora",
    "tokenB": "dfyn"
  },
  "zee-dfyn": {
    "address": "0xfB75d80e141b91535dA513370D4Dd33D0E19d308",
    "stakingToken": "0x478577fd3Ae5d1c63C3023A2E828f22698589A0A",
    "tokenA": "zee",
    "tokenB": "dfyn"
  },
  "booty-dfyn": {
    "address": "0x2980acaa5cBcA993B3868ef54B88CE328bCA06f7",
    "stakingToken": "0xa9137d4267e99F8445346a4EAc0bCDAfe3008892",
    "tokenA": "booty",
    "tokenB": "dfyn"
  }
}

export const curvePoolConfig = {
  "aave": {
    "title": "am3CRV",
    "address": "0x445FE580eF8d70FF569aB36e80c647af338db351",
    "lptoken": "0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171",
    "abi": require("../../crvABIs/CrvAaveABI.json"),
    "coins": ["amdai", "amusdc", "amusdt"],
    "underlyingcoins": ["dai", "usdc", "usdt"]
  }
}

export const sushiConfig = {
  "chef": {
    "address": "0x0769fd68dFb93167989C6f7254cd0D766Fb2841F",
    "abi": require("./abis/SushiMaticMiniChefv2ABI.json")
  }
}

export const quickRewardsConfig = {
  "rewardfactory": {
    "address": "0x5eec262B05A57da9beb5FE96a34aa4eD0C5e029f",
    "abi": require("./abis/Quick_StakingRewardsFactoryABI.json")
  }
}
export const quickRewardFactoryConfig = {
  "matic-quick": {
    "address": "0x7Ca29F0DB5Db8b88B332Aa1d67a2e89DfeC85E7E",
    "stakingToken": "0x019ba0325f1988213d448b3472fa1cf8d07618d7",
    "tokenA": "wmatic",
    "tokenB": "quick"
  },
  "matic-eth": {
    "address": "0x8FF56b5325446aAe6EfBf006a4C1D88e4935a914",
    "stakingToken": "0xadbF1854e5883eB8aa7BAf50705338739e558E5b",
    "tokenA": "wmatic",
    "tokenB": "eth"
  }
 
}

export const dQuickConfig = {
  "dquick": {
    "address": "0xf28164A485B0B2C90639E47b0f377b4a438a16B1",
    "abi": require("./abis/dQuick.json")
  }
}


//0x7Ca29F0DB5Db8b88B332Aa1d67a2e89DfeC85E7E

// booty-dfyn 0xa9137d4267e99F8445346a4EAc0bCDAfe3008892
// 0x2980acaa5cBcA993B3868ef54B88CE328bCA06f7
