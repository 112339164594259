import { chefConfigs,
         routerConfig } from './chains/fantom/Configs.js';


import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import { Joo, Flow, FlowLP } from './JooishWeb3.js'
import { SwapRouter } from './JooishRouter.js'
import Checkbox from './Checkbox.js'
import { Forge } from './LPForge.js'
import { CrvGod } from './CrvGod.js'
import { Pool,
         PoolNameRewardContainer } from './Pools.js'

import { masterChefFactory } from './Chefs.js'



import { getAmountOut, swapXforY } from './swaprouter.js'

import { Token, BalanceWithIcon, LP, LazyToken, Lizzy } from './token.js'

import { WalletEntry, SimpleWalletEntry, Collapsor } from './Wallet.js'

import { tokenConfigsFTM } from './tokenconfig.js'

import { DfynPool } from './DfynPool.js'
import { QuickPool } from './QuickPool.js'

import { tokenNames,
         tokenNameIconMap, 
         fromTokenNameToIcon,
         fromTokenNameToAddress, 
         fromTokenNameTo, 
          formatTokenBalance, 
          fromAddressTo, 
          tokenNameAddyMap,
          searchAddress, 
          cleanFetchedName, 
          safeCompOrCleanName } from './tokenUtilities.js'

import { chainMap } from './ChainTools.js'

import { BigNumber } from '@ethersproject/bignumber'

import { Swapper } from './SwapperUI.js'

import { logDate } from './Utils.js'
import { ControlPanel } from './ControlPanel.js'


const CONNECT_TEXT   = 'Connect';
const CONNECTED_TEXT = 'Connected';

// All supported MasterChef contract names for this network
const chefNames = Object.keys(chefConfigs);

var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')
const tokenConfigs = tokenConfigsFTM
export function YieldFarm() {

// ppChef = poolsPerChef {chefName: {pool-id: Pool}, ...}
function ppChefReducer(ppChef, action) {

  switch (action.type) {

    case 'full-state-overwrite':
      return action.value

    case 'new-pool':
      // create a new pool for the provided chef and pid
      return {
        ...ppChef,
        [action.chefName]: {
          ...ppChef[action.chefName],
          [action.pid]: action.value
        }
      }

    case 'update-entry-multi-pids':
      // for a chef, update all of the provided pids
      let pids = action.pids

      let newPpChef = {
        ...ppChef,
        [action.chefName]: {
          ...ppChef[action.chefName]
        }
      }

      for (let idx = 0; idx < action.pids.length; idx++) {
        let pid = action.pids[idx]
        newPpChef[action.chefName][pid][action.field] = action.value
      }
      return newPpChef

    case 'update-entry':
      // update a specific field's value for the provided chef and pid
      let pools = ppChef[action.chefName]
      let pid = action.pid

      let newPool = {}
      if (pid in pools) {
        newPool = {...pools[pid], [action.field]: action.value}
      } else {
        newPool = new Pool(pid)
      }
      return {
        ...ppChef,
        [action.chefName]: {
          ...pools,
          [pid]: newPool
        }
      }

    default:
      throw new Error("ppChef reducer called with invalid action.type")
  }
  return ppChef
}

  /************************************************************
   * State, constants, variables
   *************************************************************/
  const [connected, setConnected] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Connect");
  const [isDisabled, setDisabled] = React.useState(false);
  const [connectedChain, setConnectedChain] = React.useState(false);

  const [accounts, setAccounts] = React.useState([]);
  function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
  function handleChainChange(chainId) {
        setConnectedChain(chainMap(window.ethereum.chainId))
    }

  // States: number of pools under each MasterChef
  //         and actual pool information and harvest-enable state
  const [numPoolsPerChef, setNumPoolsPerChef] = React.useState(
    chefNames.reduce((nums, name)  => ({...nums,  [name]: 0}),  {}));
  //const [poolsPerChef, setPoolsPerChef] = React.useState(
  //  chefNames.reduce((pools, name) => ({...pools, [name]: {}}), {}));
  const [poolsPerChef, ppChefDispatch] = React.useReducer(ppChefReducer,
    chefNames.reduce((pools, name) => ({...pools, [name]: {}}), {}));



  const [onboardingDone, setOnboardingDone] = React.useState(false);
  const [numPoolsLoaded, setNumPoolsLoaded] = React.useState(false);
  const [poolDetailsLoaded, setPoolDetailsLoaded] = React.useState(false);

  // memoized masterChefs
  // takes chefNames, uses masterChefFactory {Pools.js} to initialize the masterChefs memo
  const masterChefs = React.useMemo(() => chefNames.reduce(
    (chefs, name) => ({
      ...chefs,
      [name]: masterChefFactory(web3, chefConfigs[name])
    }),
    {}
  ), []);
  //));
  //);


  // request eth accounts from the browser
  // set accounts state to the response
  // set connected state to true
  const onClickCONNECT = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((newAccounts) => {
          setAccounts(newAccounts)
          setConnected(true)})
    }
  };





  // add accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);

  // add chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])


  React.useEffect(() => {
    // check if metamask is installed and ready, and if so, collect some farm info
    if (connected) {
      if (!onboardingDone) {
        console.log("onBoarding()");
        if (accounts.length > 0) {
          setButtonText(CONNECTED_TEXT + " to " + chainMap(window.ethereum.chainId));
          setDisabled(true);
          setOnboardingDone(true);
          console.log("Onboarding complete.");

        } else {
          setButtonText(CONNECT_TEXT);
          setDisabled(false);
        }
      }
    }
  }, [connected]);


  let tryLoadNumPools = () => {
   if (!numPoolsLoaded) {
      console.log("Getting number of pools for each chef");
      let batch = new web3.BatchRequest()
      chefNames.forEach( (_chefName, _chefIdx) => {
        batch.add(
          masterChefs[_chefName].probeNumPools(
            window.ethereum.selectedAddress,
            (_, _numPools) => {  // callback
              if (_numPools > 0) {
                setNumPoolsPerChef((_prevNums,) => ({
                  ..._prevNums,
                  [_chefName]: _numPools
                }))

                if ( _chefIdx + 1 === chefNames.length ) {
                  setNumPoolsLoaded(true);
                }
              }
            },
            true // requestMode
          ) // batch add
        )
      }) // for each
      batch.execute()
     }
  }

  const [poolRewards , setPoolRewards] = React.useState({})
  const [poolInfos   , setPoolInfos]   = React.useState({})
  const [userInfos   , setUserInfos]   = React.useState({})
  const [poolNames   , setPoolNames]   = React.useState({})

  const [poolRewardsDone , setPoolRewardsDone] = React.useState(false)
  const [poolInfoDone    , setPoolInfoDone]    = React.useState(false)
  const [userInfoDone    , setUserInfoDone]    = React.useState(false)
  const [poolNamesDone   , setPoolNamesDone]   = React.useState(false)

  const loadPoolInfo = () => {

    console.log("Collecting pending rewards and other pool info")
    var _walletAddress = window.ethereum.selectedAddress

    let numChefs = chefNames.length

    let rewardsBatch = new web3.BatchRequest()
    let poolRewardsPerChef = {}
    chefNames.forEach( (_chefName, _chefIdx) => {
      let _chef = masterChefs[_chefName]
      let _numPools = numPoolsPerChef[_chefName]
      poolRewardsPerChef[_chefName] = {}
      for (let _pid = 0; _pid < _numPools; _pid++) {
        rewardsBatch.add(
          _chef.probePendingRewards(_pid, _walletAddress,
            (_, _rewards) => {  // callback
              poolRewardsPerChef[_chefName][_pid] = _rewards
              if ((_pid === _numPools-1) && (_chefIdx === numChefs-1)) {
                // last req in batch
                setPoolRewards(poolRewardsPerChef)
                setPoolRewardsDone(true)
              }
            },
            true // requestMode
          )
        )
      }
    })
    console.log("Executing batch to collect pool rewards...")
    rewardsBatch.execute()

    let poolInfoBatch = new web3.BatchRequest()
    let poolInfoPerChef = {}
    chefNames.forEach( (_chefName, _chefIdx) => {
      let _chef = masterChefs[_chefName]
      let _numPools = numPoolsPerChef[_chefName]
      poolInfoPerChef[_chefName] = {}
      for (let _pid = 0; _pid < _numPools; _pid++) {
        poolInfoBatch.add(
          _chef.probePoolInfo(_pid, _walletAddress,
            (_, _info) => {  // callback
              poolInfoPerChef[_chefName][_pid] = _info
              if ((_pid === _numPools-1) && (_chefIdx === numChefs-1)) {
                // last req in batch
                setPoolInfos(poolInfoPerChef)
                console.log("Callback done for the last poolInfo request in batch")
                setPoolInfoDone(true)
              }
            },
            true // requestMode
          )
        )
      }
    })
    console.log("Executing batch to collect poolInfo...")
    poolInfoBatch.execute()

    let userInfoBatch = new web3.BatchRequest()
    let userInfoPerChef = {}
    chefNames.forEach( (_chefName, _chefIdx) => {
      let _chef = masterChefs[_chefName]
      let _numPools = numPoolsPerChef[_chefName]
      userInfoPerChef[_chefName] = {}
      for (let _pid = 0; _pid < _numPools; _pid++) {
        userInfoBatch.add(
          _chef.probeUserInfo(_pid, _walletAddress,
            (_, _userinfo) => {  // callback
              userInfoPerChef[_chefName][_pid] = _userinfo
              if ((_pid === _numPools-1) && (_chefIdx === numChefs-1)) {
                // last req in batch
                setUserInfos(userInfoPerChef)
                setUserInfoDone(true)
              }
            },
            true // requestMode
          )
        )
      }
    })
    console.log("Executing batch to collect userInfo...")
    userInfoBatch.execute()
  }

  React.useEffect(() => {
    if (poolInfoDone) {
      console.log("Basic poolInfo collected, using the results to get poolNames...")
      logDate()
      var _walletAddress = window.ethereum.selectedAddress
      let numChefs = chefNames.length

      let batch = new web3.BatchRequest()
      let poolNamesPerChef = {}
      chefNames.forEach( (_chefName, _chefIdx) => {
        let _chef = masterChefs[_chefName]
        let _numPools = numPoolsPerChef[_chefName]
        poolNamesPerChef[_chefName] = {}
        for (let _pid = 0; _pid < _numPools; _pid++) {
          let _tokenAddress = poolInfos[_chefName][_pid][0]
          batch.add(
            _chef.probePoolName(_tokenAddress, _walletAddress,
              (_, _name) => {  // callback
                let cn = cleanFetchedName(_name)
                console.log(cn)
                let dition = (cn=="spirit lps")
                if (dition) {
                    console.log("let it flow")
                    let flow = new FlowLP(web3, _walletAddress, _tokenAddress)
                    flow.tokens((res) => {

                      let rez = res

                      let rezz =  []
                      rez.forEach((x, index) => {
               
                        rezz[index] = searchAddress(x, 'name')
                      }) 

                      console.log(rezz)

                      poolNamesPerChef[_chefName][_pid] = <Lizzy pair={rezz} />
                      if ((_pid === _numPools-1) && (_chefIdx === numChefs-1)) {
                        setPoolNames(poolNamesPerChef)
                        setPoolNamesDone(true)
                      }
                    })
                  } else {
                    poolNamesPerChef[_chefName][_pid] = safeCompOrCleanName(cn, <Token name={cn}/>)
                    if ((_pid === _numPools-1) && (_chefIdx === numChefs-1)) {
                      setPoolNames(poolNamesPerChef)
                      setPoolNamesDone(true)
                    }
                  }
                

               
              },
              true // requestMode
            )
          )
        }
      })
      console.log("Executing batch to collect poolNames...")
      batch.execute()
    }
  }, [poolInfoDone])

  React.useEffect(() => {
    if (poolRewardsDone && poolInfoDone && userInfoDone && poolNamesDone) {
      console.log("Chef/pool info successfully collected. Updating state.")
      var _walletAddress = window.ethereum.selectedAddress

      let tmp_poolsPerChef = {}
      chefNames.forEach( (_chefName, _chefIdx) => {
        let _chef = masterChefs[_chefName]
        let _numPools = numPoolsPerChef[_chefName]
        tmp_poolsPerChef[_chefName] = {}
        for (let _pid = 0; _pid < _numPools; _pid++) {
          let _tokenAddress = poolInfos[_chefName][_pid][0]
          tmp_poolsPerChef[_chefName][_pid] = new Pool(
            _pid,
            poolNames[_chefName][_pid],
            poolRewards[_chefName][_pid],
            _tokenAddress,
            userInfos[_chefName][_pid][0], // user balance
            () => { // deposit function
              var j = new Flow(web3, _walletAddress, _tokenAddress);
              setPendingTransaction(true)

              j.grabTokenBalance__THEN(_tokenAddress, _walletAddress,
                (bal) => {
                  console.log(bal)
                  j.approveIfNeeded(
                    chefConfigs[_chefName]["address"], 
                    bal, 
                    bal, 
                    () => {
                      _chef.deposit(_pid, bal, _walletAddress).then((res) => {
                        tryGetBalances()
                        setPendingTransaction(false)
                      })
                    }
                    
                  )
                }
              )
            }
          )
        }
      })
      ppChefDispatch({type: 'full-state-overwrite', value: tmp_poolsPerChef})
      setPoolDetailsLoaded(true)
      console.log("Done loading all chef/pool information!")
      logDate()
    }
  }, [poolRewardsDone, poolInfoDone, userInfoDone, poolNamesDone]);

  const getExpectedNumberOfPools = () => {

    let expectedNumberOfPools = 0;
    chefNames.forEach( (_chefName) => {

      expectedNumberOfPools += Number(numPoolsPerChef[_chefName])
    })

    return expectedNumberOfPools

  }

  const getCurrentNumberOfPoolsInPoolsPerChef = () => {
    let numberOfPoolsInPoolsPerChef = 0;
    chefNames.forEach( (_chefName) => {
      numberOfPoolsInPoolsPerChef += Number(poolsPerChef[_chefName].length)
    })


    return numberOfPoolsInPoolsPerChef

  }

  const checkIfPoolsPerChefIsTheRightSize = () => {
    if (getExpectedNumberOfPools() === 0) return false
    if (getCurrentNumberOfPoolsInPoolsPerChef() === 0) return false
    return (getCurrentNumberOfPoolsInPoolsPerChef() === getExpectedNumberOfPools())
  }



  const tryLoadPoolInfo = () => {
    console.log("LoadPools /" + poolDetailsLoaded + "/ /" + numPoolsPerChef[chefNames[0]])

    if (!poolDetailsLoaded && getExpectedNumberOfPools() > 0 ) {
      logDate()
      loadPoolInfo()
    }
  }


  const [isNotTryingToLoadPools, setIsNotTryingToLoadPools] = React.useState(true);

  // if onboarding is Done then try to load the number of pools
  React.useEffect(() => {
    if (onboardingDone) {
      if (chainMap(window.ethereum.chainId) === "Fantom") {
         tryLoadNumPools()
      }  else {
        alert("you are not connected to fantom, please switch")
      }
     
    }
  }, [onboardingDone]);

  // then try and load Pool Info
  React.useEffect(() => {

      if(onboardingDone && numPoolsLoaded && isNotTryingToLoadPools) {
        console.log("trying to try to load Pools")
        tryLoadPoolInfo()
        setIsNotTryingToLoadPools(false)
      }

  }, [numPoolsLoaded]);

  const [bal_ftm    , setBal_ftm]    = React.useState(0)
  const [bal_wftm    , setBal_wftm]    = React.useState(0)
  const [bal_usdc    , setBal_usdc]    = React.useState(0)
  const [bal_dai   , setBal_dai]    = React.useState(0)

  const [bal_spirit    , setBal_spirit]    = React.useState(0)
  const [bal_chad    , setBal_chad]    = React.useState(0)

  const [bal_boo    , setBal_boo]    = React.useState(0)
  const [bal_xboo    , setBal_xboo]    = React.useState(0)
  const [bal_steak    , setBal_steak]    = React.useState(0)
  const [bal_xsteak    , setBal_xsteak]    = React.useState(0)
  const [bal_fusd    , setBal_fusd]    = React.useState(0)
  const [bal_ifusd    , setBal_ifusd]    = React.useState(0)
  const [bal_brush   , setBal_brush]    = React.useState(0)

  const [bal_ori   , setBal_ori]    = React.useState(0)
  const [bal_aurora   , setBal_aurora]    = React.useState(0)

  const [bal_tomb   , setBal_tomb]    = React.useState(0)
  const [bal_tshare  , setBal_tshare]    = React.useState(0)


  const tryGetBalances = (callback = () => {}) => {
    var batch = new web3.BatchRequest()
    var j = new Joo();

    //get Native (MATIC) balance
    batch.add(
      web3.eth.getBalance
        .request(
          window.ethereum.selectedAddress,
          'latest',
          (_, bal) => { eval("setBal_ftm(bal)") }
          )
        )

    const tokenNames = Object.keys(tokenConfigs)

    //get token balances
    tokenNames.forEach( (name) => {
      const stringState = "(_, bal) => { setBal_" + name + "(bal)}"
      batch.add(
        j.balanceRequest(
          fromTokenNameToAddress(name),
          window.ethereum.selectedAddress,
          eval(stringState))
        )
    })
    batch.execute()
  }

  const getTokenBalanceFromConfig = (configname, callback) => {
    var j = new Joo();
    j.grabTokenBalance__THEN(tokenConfigs[configname]["address"], window.ethereum.selectedAddress, callback)
  }
  const getTokenBalance = (configname, callback) => {
    var j = new Joo();
    j.grabTokenBalance__THEN(tokenConfigs[configname]["address"], window.ethereum.selectedAddress, 
      (bal) => {
        eval('setBal_' + configname + "(" + bal + ")")
        callback()
      })
  }

  // core loader hook
  const [isReadyToLoadImages, setIsReadyToLoadImages] = React.useState(false)

  React.useEffect(() => {
    if(poolDetailsLoaded && !isReadyToLoadImages) {
      console.log("chefs supposedly loaded")
      tryGetBalances()
      setIsReadyToLoadImages(true)

    
    }
  }, [poolDetailsLoaded]);

  /************************************************************
   * Pool harvesting and staking - Pool-enables and clicks
   *************************************************************/

  const togglePoolHarvest = (_chefName, _pid, _enabled) => {
    console.log("setPoolHarvestEnabled(" + _chefName + ")");
    ppChefDispatch({type: 'update-entry', chefName: _chefName, pid: _pid, field: 'harvestEnabled', value: _enabled})
  }

  const toggleChefHarvests = (_chefName, _pids=[], _enabled) => {
    ppChefDispatch(
      {
        type: 'update-entry-multi-pids',
        chefName: _chefName,
        pids: _pids,
        field: 'harvestEnabled',
        value: _enabled
      }
    )
  }

  const toggleAllHarvests = (_enabled) => {
    chefNames.forEach(_chefName => {
      toggleChefHarvests(_chefName, [...Array(Number(numPoolsPerChef[_chefName])).keys()], _enabled)
    })
  }

  const deselectAll = () => { toggleAllHarvests(false) }
  const enableHarvestAllPending = () => {
    chefNames.forEach(_chefName => {
      const allPids = [...Array(Number(numPoolsPerChef[_chefName])).keys()]
      const pidsWithRewards = allPids.filter(pid => poolsPerChef[_chefName][pid].hasRewards())
      toggleChefHarvests(_chefName, pidsWithRewards, true)
    })
  }

  const handleCheckboxChangeFuncGen = (_chefName) => {
    return ((changeEvent) => {togglePoolHarvest(_chefName, changeEvent.target.dataset.pid, changeEvent.target.checked)});
  }

  const harvestSelection = () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        let _walletAddress = window.ethereum.selectedAddress;
        chefNames.forEach(_chefName => {
          for (let _pid = 0; _pid < numPoolsPerChef[_chefName]; _pid++) {
            let pool = poolsPerChef[_chefName][_pid]
            if (pool.hasRewards() && pool.harvestEnabled) {
              masterChefs[_chefName].harvestPool(_pid, _walletAddress);
            }
          }
        })
      }
    }
  }

  const stakeAllChefAssetOnClickFuncGen = (_chefName) => {
    return () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled() && accounts.length > 0) {
          setPendingTransaction(true)
          masterChefs[_chefName].stakeAllChefAsset(window.ethereum.selectedAddress, () => {
            setPendingTransaction(false)
            tryGetBalances()
          })
      }
    }
  }

  const Chiggedy = (props) => {
    return (
    <Checkbox
      pid={props.pool.pid}
      label={props.pool.toString()}
      isSelected={props.pool.harvestEnabled}
      onCheckboxChange={props.onCheckboxChange}
      key={props.pool.pid}
      hasRewards={props.pool.hasRewards()}
    />)
  }

  const createCheckboxes = (_pools, _handleCheckboxChange) => {
    return ( Object.keys(_pools).map((pid) => <Chiggedy key={pid} pool={_pools[pid]} onCheckboxChange={_handleCheckboxChange} />))
  }

  const CheckboxSet = (props) => {
    let leChiggies = createCheckboxes(props.pools, props.handleCheckboxChange);
    return(
      <div className="container">
          {leChiggies}
      </div>
      )
  }


  const [isHidingNonStaked, setIsHidingNonStaked] = React.useState(true);
  const toggleHideNonStaked = () => {
    if(isHidingNonStaked) {
      setIsHidingNonStaked(false)
    } else {
      setIsHidingNonStaked(true)
    }
  }




  const RustyTrombone = (props) => {
    return (
      <div className="CheckboxSetContainer">
        <h2>{props.protocol}</h2>
        <CheckboxSet
          pools={poolsPerChef[props.protocol]}
          handleCheckboxChange={handleCheckboxChangeFuncGen(props.protocol)} />
      </div>

      )
  }

  

    const [pendingTransaction, setPendingTransaction] = React.useState(false)

    const handlePendingTransaction = (res) => {
      setPendingTransaction(false)

    }


    //----------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------------------
    // TOGGLES

    

    const [TOGGLE__collapsible, setTOGGLE__collapsible]  = React.useState(false);
    const toggleCollapsible = () => {
      if (TOGGLE__collapsible) {
        setTOGGLE__collapsible(false)
      } else {
        setTOGGLE__collapsible(true)
      }
    }


    const [TOGGLE__collapsible_StableCoins, setTOGGLE__collapsible_StableCoins] = React.useState(false);
    const toggleCollapsible__StableCoins = () => {
      if (TOGGLE__collapsible_StableCoins) {
        setTOGGLE__collapsible_StableCoins(false)
      } else {
        setTOGGLE__collapsible_StableCoins(true)
      }
    }

    const [TOGGLE__collapsible_ShitCoins, setTOGGLE__collapsible_ShitCoins] = React.useState(false);
    const toggleCollapsible__ShitCoins = () => {
      if (TOGGLE__collapsible_ShitCoins) {
        setTOGGLE__collapsible_ShitCoins(false)
      } else {
        setTOGGLE__collapsible_ShitCoins(true)
      }
    }

    const [TOGGLE__Swapper, setTOGGLE__Swapper] = React.useState(false);
    const toggleSwapper = () => {
      if (TOGGLE__Swapper) {
        setTOGGLE__Swapper(false)
      } else {
        setTOGGLE__Swapper(true)
      }
    }

    const [TOGGLE__Forge, setTOGGLE__Forge] = React.useState(false);
    const toggleForge = () => {
      if (TOGGLE__Forge) {
        setTOGGLE__Forge(false)
      } else {
        setTOGGLE__Forge(true)
      }
    }

    const [TOGGLE__Crv, setTOGGLE__Crv] = React.useState(false);
    const toggleCrv = () => {
      if (TOGGLE__Crv) {
        setTOGGLE__Crv(false)
      } else {
        setTOGGLE__Crv(true)
      }
    }
  
    const [TOGGLE__collapsible_Farm, setTOGGLE__collapsible_Farm] = React.useState(false);
    const toggleCollapsible__Farm = () => {
      if (TOGGLE__collapsible_Farm) {
        setTOGGLE__collapsible_Farm(false)
      } else {
        setTOGGLE__collapsible_Farm(true)
      }
    }
    

    return (
      <div>
      <button className="harvest-button harvest-button--mask" disabled={isDisabled} onClick={onClickCONNECT}>
          {buttonText}
        </button>
      <div className={"harvest-container TOGGLESHOWALL--" + isHidingNonStaked + " isReadyToLoadImages--" + isReadyToLoadImages} >
        <h1 className="farmgod"><span>FARMGOD</span></h1>


        <div className={"pending pending--" + pendingTransaction}>PENDING</div>

        <div className="farmland">
          <div className="harvest-buttons">
            <button
              className="button"
              onClick={enableHarvestAllPending}
              title="selectpending">
                Select Pending
            </button>
            <button
              className="button"
              onClick={deselectAll}
              title="clearselection">
                Clear Selections
            </button>
            <button
              className="button"
              onClick={harvestSelection}
              title="harvestselection">
                Harvest Selection
            </button>
          </div>
          <RustyTrombone protocol="spiritswap" />
          <RustyTrombone protocol="spookyswap" />
          <RustyTrombone protocol="chad" />
          <RustyTrombone protocol="paintswap" />
          <RustyTrombone protocol="hyperjump" />
        
 
      </div>
        <ControlPanel />

        <div className="controls controls--lesser">
          <button
            className="button"
            onClick={toggleHideNonStaked}
            data-id="stake - transact (not payable)"
            title="toggleshowall">
              Toggle Show All
          </button>
        </div>

        <div className="wallet">
          <WalletEntry tokenName="Fantom" tokenImgSrc={tokenNameIconMap.get("wftm").icon} tokenBalance={bal_ftm} tokenDecimals="18" />
          <SimpleWalletEntry tokenName="wftm" tokenBalance={bal_wftm} />           
          
          
          <Collapsor name="The Big Three"> 
            <SimpleWalletEntry tokenName="spirit" tokenBalance={bal_spirit} />
            <SimpleWalletEntry tokenName="boo" tokenBalance={bal_boo} />
            <SimpleWalletEntry tokenName="xboo" tokenBalance={bal_xboo} />
            <SimpleWalletEntry tokenName="ori" tokenBalance={bal_ori} />
            <SimpleWalletEntry tokenName="aurora" tokenBalance={bal_aurora} />
          </Collapsor>
          <Collapsor name="Steak"> 
            <SimpleWalletEntry tokenName="steak" tokenBalance={bal_steak} />
            <SimpleWalletEntry tokenName="xsteak" tokenBalance={bal_xsteak} />
            <SimpleWalletEntry tokenName="fusd" tokenBalance={bal_fusd} />
            <SimpleWalletEntry tokenName="ifusd" tokenBalance={bal_ifusd} />
          </Collapsor>
          <Collapsor name="Stablecoins"> 
            <SimpleWalletEntry tokenName="usdc" tokenBalance={bal_usdc} />
            <SimpleWalletEntry tokenName="dai" tokenBalance={bal_dai} />
          </Collapsor>
          <Collapsor name="Farmin'"> 
            <SimpleWalletEntry tokenName="chad" tokenBalance={bal_chad} />
            <SimpleWalletEntry tokenName="brush" tokenBalance={bal_brush} />
          </Collapsor>
          <Collapsor name="Tomb"> 
            <SimpleWalletEntry tokenName="tomb" tokenBalance={bal_tomb} />
            <SimpleWalletEntry tokenName="tshare" tokenBalance={bal_tshare} />
          </Collapsor>
        </div>

        {/*
        <Swapper web3={web3} UPDATER={() => {tryGetBalances()}} isOpen={TOGGLE__Swapper} />
        <button className="GodSwap__Toggle" onClick={toggleSwapper}>GODSWAP</button>

        <Forge web3={web3} isOpen={TOGGLE__Forge} />
        <button className="GodForge__Toggle" onClick={toggleForge}>GODFORGE</button> */}

      
      </div>
      </div>
    );
}
