import React from 'react'

export const ControlPanel = (props) => {
	const [TOGGLE_CONTROL_PANEL, setTOGGLE_CONTROL_PANEL]  = React.useState(false);
    const toggleControlPanel = () => {
      if (TOGGLE_CONTROL_PANEL) {
        setTOGGLE_CONTROL_PANEL(false)
      } else {
        setTOGGLE_CONTROL_PANEL(true)
      }
    }
	return (
		<div className={"ControlPanel ControlPanel--" + TOGGLE_CONTROL_PANEL}>
		  <button
		    className="button control-panel-toggle"
		    onClick={toggleControlPanel}
		    data-id="stake - transact (not payable)"
		    title="stake - transact (not payable)">
		      Toggle Controls
		  </button>
		  {props.children}
		</div>
	)
}

