import { MasterChef } from './../../Chefs.js';

export class PolycatMasterChef extends MasterChef {
  deposit(_pid, _amount, _walletAddress) {
    // OVERRIDE
    // same as baseclass but with referall address arg (set to 0x0)
    return this._contract.methods.deposit(_pid, _amount,
                                         "0x0000000000000000000000000000000000000000"
                                        ).send({from: _walletAddress});
  }
}

export class WEXPolyMasterChef extends MasterChef {
  deposit(_pid, _amount, _walletAddress) {
    // OVERRIDE
    // same as baseclass but with _withdrawRewards argument set to true
    return this._contract.methods.deposit(_pid, _amount,
                                         true
                                        ).send({from: _walletAddress});
  }
}

export class AuguryMC extends MasterChef {
  deposit(_pid, _amount, _walletAddress) {
    // OVERRIDE
    // same as baseclass but with _withdrawRewards argument set to true
    return this._contract.methods.deposit(_pid, _amount,
                                         "0x0000000000000000000000000000000000000000"
                                        ).send({from: _walletAddress});
  }
}
