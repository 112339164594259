import Web3 from 'web3'

export function Joo3 () {
  return new Web3(Web3.givenProvider || 'http://localhost:8545')
}

export class Joo {
  constructor (_web3=null) {
    if (_web3) {
      this.X = _web3
    } else {
      this.X = new Web3(Web3.givenProvider || 'http://localhost:8545')
    }
    this.abi = require('./GenericABI.json')
  }

  createGenContract (tokenAddress) {
    return new this.X.eth.Contract(this.abi, tokenAddress);
  }

  grabTokenBalance__THEN ( tokenAddress, walletAddress, callback = () => {} ) {
    let genContract = this.createGenContract(tokenAddress)
    return genContract.methods.balanceOf(walletAddress).call({from: walletAddress}).then(callback)
  }

  grabTokenBalanceFromConfig ( configs, configname, walletAddress, callback = () => {} ) {
    this.grabTokenBalance__THEN(
                configs[configname]["address"],
                walletAddress,
                callback)
  }

  getNativeBalance = ( walletAddress, callback = () => {} ) => {
      this.X.eth.getBalance(walletAddress)
          .then(callback)
      }

  balanceRequest ( tokenAddress, walletAddress, callback = () => {}) {
    let genContract = this.createGenContract(tokenAddress)
    return genContract.methods.balanceOf(walletAddress).call.request({from: walletAddress}, callback)
  }

  approve (token, spender, walletAddress, amount, callback = () => {}) {
    let genContract = this.createGenContract(token)
    return genContract.methods.approve(spender, amount).send({from: walletAddress}).then(callback)
  }

  allowance (token, spender, walletAddress, callback = () => {}) {
    let genContract = this.createGenContract(token)
    return genContract.methods.allowance(walletAddress, spender).call({from: walletAddress}).then(callback)
  }
}


export class Flow extends Joo {
  constructor(_web3=null, walletAddress, tokenAddress) {
    super(_web3)
    this.captain = walletAddress
    this.token = tokenAddress
    this.tract = new _web3.eth.Contract(require('./GenericABI.json'), tokenAddress)
  }

  allowance (spender, callback = () => {}) {
    return this.tract.methods.allowance(this.captain, spender).call({from: this.captain}).then(callback)
  }
  approve (spender, amount, callback = () => {}) {
    return this.tract.methods.approve(spender, amount).send({from: this.captain}).then(callback)
  }


  approveIfNeeded (spender, amount, atLeast, callback = () => {}) {
    this.allowance(
      spender,
      (amt) => {
        console.log("Allowance: "+amt)
        console.log("AtLeast: "+atLeast)
        console.log(amt.toString().length)
        if (amt >= atLeast || amt.toString().length > 18) {
          console.log("enough allowed")
          callback()
        } else {
          console.log("request allowance")
          this.approve( spender, amount, callback)
        }
      }
      )
  }
  

  


}

export class FlowLP extends Joo {
  constructor(_web3=null, walletAddress, tokenAddress) {
    super(_web3)
    this.captain = walletAddress
    this.token = tokenAddress
    this.tract = new _web3.eth.Contract(require('./GenLPABI.json'), tokenAddress)
  }

  allowance (spender, callback = () => {}) {
    return this.tract.methods.allowance(this.captain, spender).call({from: this.captain}).then(callback)
  }
  approve (spender, amount, callback = () => {}) {
    return this.tract.methods.approve(spender, amount).call({from: this.captain}).then(callback)
  }


  approveIfNeeded (spender, amount, atLeast, callback = () => {}) {
    this.allowance(
      spender,
      (amt) => {
        console.log(amt.toString().length)
        if (amt.toString().length > 18) {
          callback()
        } else {
          if (amt >= atLeast ) {
            callback()
          } else {
            this.approve( spender, amount, callback)
          }
        }
        
      }
      )
  }

  tokens (callback = () => {}) {
    this.tract.methods.token0().call({from: this.captain}).then((addyA) => {
      this.tract.methods.token1().call({from: this.captain}).then((addyB) => {
        callback([addyA, addyB])
      })
    })
  }
  

  


}