import { tokenConfigsFTM } from './tokenconfig.js'
import { tokenNames, tokenNameIconMap, fromTokenNameTo, formatTokenBalance } from './tokenUtilities.js'
import { Token } from './token.js'
import React from 'react'
import { setsAreEquiv, getPairsFor } from './KnownPairs.js'

import { SwapRouter, XXX } from './JooishRouter.js'
import { swapXforY } from './swaprouter.js'
import { routerConfig } from './chains/fantom/Configs.js';
import { Joo, Flow } from './JooishWeb3.js'

import { MW } from './MaticWrapper.js'

import { BigNumber } from '@ethersproject/bignumber';
import numeral from 'numeral'
/*
const DFYN = XXX(routerConfig["dfyn"]["abi"], routerConfig["dfyn"]["address"])
    const QUICK = XXX(routerConfig["quickswap"]["abi"], routerConfig["quickswap"]["address"])
    const SUSHI = XXX(routerConfig["sushi"]["abi"], routerConfig["sushi"]["address"])
    const ELK = XXX(routerConfig["elk"]["abi"], routerConfig["elk"]["address"])
    */
const tokenConfigs = tokenConfigsFTM
const HYPER = XXX(routerConfig["hyperjump"]["abi"], routerConfig["hyperjump"]["address"])
const SPIRIT = XXX(routerConfig["spiritswap"]["abi"], routerConfig["spiritswap"]["address"])
const SPOOKY = XXX(routerConfig["spookyswap"]["abi"], routerConfig["spookyswap"]["address"])
export const TokenSelector__Token = (props) => {
  return (
      <div id={"token-" + props.name} className={"TokenSelector__Token SpekTok--" + props.name} onClick={props.onClick}>
        <div className="dummy-bg"></div>
        <Token name={props.name} />
      </div>

    )
}

export const TokenSelector = (props) => {

  return (
    <div className={"GodSwap__TokenSelector GodSwap__TokenSelector--" + props.isOpen}>
      {tokenNames.map((x, index) => <TokenSelector__Token key={index} name={x} onClick={() => props.onTokenSelect(x)} />)}
    </div>

  )
}

const maticPairs = getPairsFor("wmatic")
const daiPairs = getPairsFor("dai")

export const Swapper = (props) => {
  const web3 = props.web3
  const UPDATER = props.UPDATER

  const [pendingTransaction, setPendingTransaction] = React.useState(false)
  const [isSelectorOpen, setIsSelectorOpen] = React.useState(false);
  const [isSelectingIn, setIsSelectingIn] = React.useState(true);
  const [selectedTokenIN, setSelectedTokenIN] = React.useState("wmatic")
  const [selectedTokenOUT, setSelectedTokenOUT] = React.useState("dai")

  const [inPairs, setInPairs] = React.useState(maticPairs)
  const [outPairs, setOutPairs] = React.useState(daiPairs)

  const [intersection, setIntersection] = React.useState([])


  const [endMatches, setEndMatches] = React.useState([])

  const swapInAmount = React.createRef()
  const [nonVisibleSwapInAmount, setNonVisibleSwapInAmount] = React.useState(0)
  const [nonVisibleSwapOutAmount, setNonVisibleSwapOutAmount] = React.useState(0)


  const [potentialOutputs, setPotentialOutputs] = React.useState(new Array())

  const toggleSelector = () => {
    if (isSelectorOpen) {
      setIsSelectorOpen(false)
    } else {
      setIsSelectorOpen(true)
    }
  }

  const openSelector = (inout) => {
    toggleSelector()
    if (inout) {
      setIsSelectingIn(true)
    } else {
      setIsSelectingIn(false)
    }

  }

  React.useEffect(() => {
    if(isSelectorOpen) {
      setIsSelectorOpen(false)
    }
  }, [props.isOpen])

  React.useEffect(() => {
    if (isSelectorOpen && isSelectingIn) {
      console.log(getPairsFor(selectedTokenIN))
      setIsSelectorOpen(false)
      setInPairs(getPairsFor(selectedTokenIN))
      
    } else if (isSelectorOpen && !isSelectingIn) {
      setIsSelectorOpen(false)
      setOutPairs(getPairsFor(selectedTokenOUT, false))
      
    } else {
      console.log("deh")
    }
 
    
    setOutPairs(getPairsFor(selectedTokenOUT, false)) 
  }, [selectedTokenIN, selectedTokenOUT])


const triggerFindRoutes = () => {
    let DO_NOT_SEARCH = false;

    setIntersection([])
    setEndMatches([])

    inPairs.forEach(inPair => {
      outPairs.forEach(outPair => {
        if (setsAreEquiv(new Set(inPair), new Set(outPair))) {
          setIntersection(inPair)
          //DO_NOT_SEARCH = true
        }
      })
    })
    

    if (!DO_NOT_SEARCH) {
      let endMatchesArray = new Array();

      inPairs.forEach( (_pair, i) => {
        let connectedPairs = outPairs.filter( y => (y.includes(_pair[1]) ) )
        if ( connectedPairs.length > 0 ) {
          let connector = connectedPairs[0][0]
          let theRoute = [selectedTokenIN, connector, selectedTokenOUT]
          endMatchesArray.push(theRoute)
        }
      })

      


      if (endMatchesArray.length > 0) {
        setEndMatches(endMatchesArray)
      } else {

        // we want to take the shorter list of pairs
        // get the non selected token pairs
      
        inPairs.forEach( (_pair, i) => {
          let connectorToken = _pair[1]

          let connectorPairs = getPairsFor(connectorToken);
    
          connectorPairs.forEach( (pair_fromConnector, i) => {

            let connectorTokenB = pair_fromConnector[1];

            outPairs.forEach( (pair_fromOut, i) => {
              if ( pair_fromOut.includes(connectorTokenB) ) {
             
               
                endMatchesArray.push([selectedTokenIN].concat(pair_fromConnector).concat([selectedTokenOUT]))
              }
            })
          })

        })
        if (endMatchesArray.length > 0) {
          setEndMatches(endMatchesArray)
        } else {
          setEndMatches([])
        }
        
      }
      

      
    }
    
  }




  const setSelectedToken = (tokenName) => {
    if (isSelectingIn) {
      setSelectedTokenIN(tokenName)
    } else {
      setSelectedTokenOUT(tokenName)
    }
  }


  React.useEffect( () => {
    if (isSelectorOpen) {

    } else {
      triggerFindRoutes()
    }

  }, [inPairs, outPairs])

  const parseTheRoute = (event, sentTarget = null) => {

    let target = (event !== 0) ? forceTheEventToComeFromWhereIFuckingSayItShould(event) : sentTarget
  
    if (target.children.length < 1) return false

    let TheNames = []
    let TheRoute = []

    const _amt = nonVisibleSwapInAmount;

    for ( let kid of target.children ) {

      if (kid.tagName !== "SPAN") {

        TheNames.push( kid.className.replace("simpleToken SpekTok--","") )
        TheRoute.push( fromTokenNameTo( kid.className.replace("simpleToken SpekTok--",""), "address") )
      }
    }

    const _dec = fromTokenNameTo(TheNames[0], "decimals") 

    
    let J = new Joo();

    var receiver = document.querySelectorAll('.Route__Results[data-id="' + target.getAttribute('data-id') +'"]')[0] 
    
    receiver.innerHTML = ""
    var swapPath = new Array(...TheRoute)
        swapPath.shift()
        swapPath.pop()
    
      
                              
    checkRouteThenFillReceivers(SPIRIT, "Spirit", _amt, swapPath, TheNames, TheRoute, receiver)
    checkRouteThenFillReceivers(SPOOKY, "Spooky", _amt, swapPath, TheNames, TheRoute, receiver)
 

  }


  function checkRouteThenFillReceivers (rtr, rtrName, _amt, swapPath, TheNames, TheRoute, receiver) {
    
      rtr.goGetAmountsOut(_amt, TheRoute, (_amounts, error) => {

      let outputs = new Array(...potentialOutputs)
      if (!error) {
        let div = document.createElement("div")

          div.onclick = () => {
            setPendingTransaction(true)
            const flow = new Flow(web3, window.ethereum.selectedAddress, TheRoute[0])
            
            flow.approveIfNeeded(
              rtr.address, 
              _amt, _amt, 
              () => {
                swapXforY(rtr, 
                  TheRoute[0], 
                  TheRoute[TheRoute.length - 1],
                  swapPath,
                  _amt, 2, () => {
                    UPDATER()
                    setPendingTransaction(false)})
              } )
              
          }
          div.innerHTML = rtrName + ": " + formatTokenBalance(
            fromTokenNameTo(TheNames[TheRoute.length - 1], "decimals"), _amounts[TheRoute.length - 1]) 
          receiver.appendChild(div)
        outputs.push(_amounts[TheRoute.length - 1])
        setPotentialOutputs(outputs)
      }
      
    })
    
  }

  React.useEffect(() => {
    console.log(potentialOutputs)

  }, [potentialOutputs])

  const forceTheEventToComeFromWhereIFuckingSayItShould = (event) => {
    console.log(event.target.tagName)
    switch (event.target.tagName) {
      case "IMG":
        return event.target.parentElement.parentElement
      case "DIV":
        return event.target.parentElement
      case "LI":
        return event.target

    }
  }

  const wrapOneMatic = () => {
    const mw = new MW()
    mw.wrapMatic(10 ** 18, (res) => {
      console.log(res)
    })
  }

  const unwrapOneMatic = () => {
    const mw = new MW()
    mw.unwrapMatic(BigNumber.from(10).pow(18), (res) => {
      console.log(res)
    })
  }


  const setInputViaButtonClick = (percentage) => {
      const j = new Joo();
      j.grabTokenBalanceFromConfig(
        tokenConfigs, 
        selectedTokenIN, 
        window.ethereum.selectedAddress,
        (bal) => {
          swapInAmount.current.value = formatTokenBalance(
            fromTokenNameTo(selectedTokenIN, "decimals"),
            BigNumber.from(percentage).mul(bal).div(100))
            setNonVisibleSwapInAmount(BigNumber.from(percentage).mul(bal).div(100));
        })

  }

  const SwapAmtBtn = (props) => {
    return <button onClick={()=>{setInputViaButtonClick(props.pct)}}>{props.pct}%</button>
  }

  const parsePotentials = () => {
    var potentials = document.getElementsByClassName("potential");
    setPotentialOutputs(new Array())
    console.log(potentialOutputs)
    let rt = null
    for (let potential of potentials) {
      rt = parseTheRoute(0, potential)
      //console.log(potentialOutputs)
    }
  }

  return(
      <div className={"GodSwap__Swapper GodSwap__Swapper--" + props.isOpen}>
        <div className="Swapper__Half" onClick={() => openSelector(true)}>
          <div className="Selected__Token">
            <Token name={selectedTokenIN} />
            <div className="Token__inoutLabel">in</div>

          </div>
          <div className="Token__KnownPairs">
            <h2>known pairs</h2>
            <ul>{inPairs.map(
              (x, index) => (
                <li key={index}>{x.map( (y, index) => <Token key={index} name={y} />)}</li>))}</ul>
          </div>
        </div>
        <div className="Swapper__Half" onClick={() => openSelector(false)}>
          <div className="Selected__Token">
            <Token name={selectedTokenOUT} />
            <div className="Token__inoutLabel">out</div>
          </div>
          <div className="Token__KnownPairs">
            <h2>known pairs</h2>
            <ul>{outPairs.map(
              (x, index) => (
                <li key={index}>{x.map((y, index) => <Token key={index} name={y} />)}</li>))}</ul>
          </div>
        </div>
        <div className="Swapper__Controls">
          <div className="Swapper__Input">
            <h3>Input Amout</h3>
            <input type="text" ref={swapInAmount}/>
            <div className="amount-btns amount-btns--2">
              <SwapAmtBtn pct="55" />
              <SwapAmtBtn pct="100" />
            </div>
            <div className="amount-btns">
              <SwapAmtBtn pct="5" />
              <SwapAmtBtn pct="10" />
              <SwapAmtBtn pct="25" />
              <SwapAmtBtn pct="50" />
  
            </div>
          </div>
          <div className="check-routes"><button onClick={parsePotentials}>Check Routes</button></div>
          <h2 className="choose-route">choose route</h2>
          <ol className="the-routes">
            <li data-id="999" className="Route__Results"></li>
          {endMatches.map( (x, index) => (
            <li key={index} data-id={index} className="Route__Results">
                
            </li>))}
          </ol>
          <div className="Swapper__Output">
            
          </div>
          <div id="Res__Catch"></div>
          <div id="potentials" className="Token__KnownPairs Swapper__Routes">

            <h2>potential routes</h2>
            <ol className={( intersection.length > 1 ) ? "int--true" : "int--false"}>
              <li key="1" data-id="999" onClick={parseTheRoute} className="potential potential--pair">
                {intersection.map((y, index) => <Token key={index} name={y} />)}
              </li>
            </ol>
            
            <ol>{endMatches.map( (x, index) => (
              <li key={index} data-id={index} onClick={parseTheRoute} className="potential">
                { x.map((y, index) => 
                <Token key={index} name={y} />)}
                <span className="Route__Results"></span>
              </li>))}
            </ol>
          </div>
        </div>
        <TokenSelector isOpen={isSelectorOpen} onTokenSelect={setSelectedToken} />
        <div className="matic-wrapper">
          <button onClick={wrapOneMatic} className="button">Wrap 1 <Token name="wmatic"/></button>
          <button onClick={unwrapOneMatic} className="button">Unwrap 1 <Token name="wmatic"/></button>
        </div>
        <div className={"pending pending--" + pendingTransaction}>PENDING</div>
      </div>
    )




}
