
import { quickRewardFactoryConfig } from './chains/matic/Configs.js'
import { formatTokenBalance,  
         fromTokenNameToAddress } from './tokenUtilities.js'
import { LazyToken } from './token.js'

 const quickFromPoolNameToTokenNames = (poolName) => {
      return [
        quickRewardFactoryConfig[poolName]["tokenA"],
        quickRewardFactoryConfig[poolName]["tokenB"]
      ]
    }

    

 export const QuickPool = (props) => {
   
      let tokens = quickFromPoolNameToTokenNames(props.poolName);

      return (
   
          <div className="dfyn-pool">
            <div className="dfyn-pair">{props.poolName}</div>
            <div className="LP-Token">
              <LazyToken name={tokens[0]} />
              <LazyToken name={tokens[1]} />
            </div>
            <div className="staked-balance">Staked: {formatTokenBalance(18, props.stakedBalance)}</div>
            <div className="dfyn-rewards" onClick={props.harvest}>
              <h3><span>Pending Rewards</span><span>Harvest</span></h3>
              <div className="reward">
                <span>{ formatTokenBalance(18, props.earnedAmount) }</span>
                <LazyToken name="quick" />
              </div>
            </div>
          </div>


      )
    }
